/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfListOfDictionaryDTO,
  Api2ResponseOfListOfLegalEntitiesDTO,
  Api2ResponseOfListOfOrderSourceInfoDTO,
  Api2ResponseOfListOfProductTagDTO,
  Api2ResponseOfListOfPromocodeTypeDTO,
  ApiV2ConciergeClientChannelResponseDTO,
  ApiV2SaleRejectionReasonResponseDTO,
  ProductTagCategory,
  ProductTagLocationType,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Dictionary<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetCategoryTreeUsingGet11
   * @summary getCategoryTree
   * @request GET:/api/v2/dictionary/categoryTree
   */
  getCategoryTreeUsingGet11 = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/v2/dictionary/categoryTree`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetBrandsUsingGet11
   * @summary getBrands
   * @request GET:/api/v2/dictionary/brands
   */
  getBrandsUsingGet11 = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/v2/dictionary/brands`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetAttributesUsingGet1
   * @summary getAttributes
   * @request GET:/api/v2/dictionary/attributes
   */
  getAttributesUsingGet1 = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/v2/dictionary/attributes`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetOrderSourceInfoUsingGet
   * @summary getOrderSourceInfo
   * @request GET:/api/v2/dictionary/order/source/info
   */
  getOrderSourceInfoUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfOrderSourceInfoDTO, void>({
      path: `/api/v2/dictionary/order/source/info`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetLegalEntitiesUsingGet
   * @summary getLegalEntities
   * @request GET:/api/v2/dictionary/legal-entities
   */
  getLegalEntitiesUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfLegalEntitiesDTO, void>({
      path: `/api/v2/dictionary/legal-entities`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetProductTags
   * @summary getProductTags
   * @request GET:/api/v2/dictionary/product-tags
   */
  getProductTags = (
    query?: {
      /** Категория тега */
      category?: ProductTagCategory;
      /** Тип тега расположения, используется при указанной категории тега LOCATION */
      locationType?: ProductTagLocationType;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductTagDTO, void>({
      path: `/api/v2/dictionary/product-tags`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetPromocodeTypes
   * @summary getPromocodeTypes
   * @request GET:/api/v2/dictionary/promocode-types
   */
  getPromocodeTypes = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfPromocodeTypeDTO, void>({
      path: `/api/v2/dictionary/promocode-types`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dictionary-controller-api-v-2
   * @name GetExpertiseReconciliationNegativeReasons
   * @summary getExpertiseReconciliationNegativeReasons
   * @request GET:/api/v2/dictionary/expertise/reconciliation/negative-reasons
   */
  getExpertiseReconciliationNegativeReasons = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfDictionaryDTO, void>({
      path: `/api/v2/dictionary/expertise/reconciliation/negative-reasons`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Concierge
   * @name GetConciergeClientChannelList
   * @summary Get concierge client channel list
   * @request GET:/api/v2/dictionary/concierge/client/channels
   */
  getConciergeClientChannelList = (params: RequestParams = {}) =>
    this.request<ApiV2ConciergeClientChannelResponseDTO, any>({
      path: `/api/v2/dictionary/concierge/client/channels`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dictionary
   * @name GetAllRejectionReasons
   * @summary Get all rejection reasons
   * @request GET:/api/v2/dictionary/sales/rejection/reasons
   */
  getAllRejectionReasons = (params: RequestParams = {}) =>
    this.request<ApiV2SaleRejectionReasonResponseDTO, any>({
      path: `/api/v2/dictionary/sales/rejection/reasons`,
      method: "GET",
      format: "json",
      ...params,
    });
}
