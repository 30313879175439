/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfbigdecimal,
  Api2ResponseOfCategoryTree,
  Api2ResponseOfConversion,
  Api2ResponseOfdouble,
  Api2ResponseOfint,
  Api2ResponseOfListOfAdditionalSizeDTO,
  Api2ResponseOfListOfAttributeDTO,
  Api2ResponseOfListOfBrandDTO,
  Api2ResponseOfListOfCategoryDTO,
  Api2ResponseOfListOfProductConditionDTO1,
  Api2ResponseOfListOfProductDTO,
  Api2ResponseOfListOfProductImageDTO,
  Api2ResponseOfListOfSizeTypeDTO1,
  Api2ResponseOfListOfStepInfo,
  Api2ResponseOflong,
  Api2ResponseOfMapOfstringAndint,
  Api2ResponseOfPageOfProductDTO,
  Api2ResponseOfPageOfProductDTOIntegrationLite,
  Api2ResponseOfProductDTO,
  Api2ResponseOfProductImageDTO,
  Api2ResponseOfStepInfo,
  ImageUploadDTO,
  ProductDTOReq,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Productpublication<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name LoadAdditionalSizesForCategoryUsingGet
   * @summary loadAdditionalSizesForCategory
   * @request GET:/api/v2/productpublication/additional-sizes/{categoryId}
   */
  loadAdditionalSizesForCategoryUsingGet = (
    categoryId: number,
    query?: {
      /**
       * sizeId
       * @format int64
       */
      sizeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfAdditionalSizeDTO, void>({
      path: `/api/v2/productpublication/additional-sizes/${categoryId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetAttributesUsingGet
   * @summary getAttributes
   * @request GET:/api/v2/productpublication/attributes/{categoryId}
   */
  getAttributesUsingGet = (categoryId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfAttributeDTO, void>({
      path: `/api/v2/productpublication/attributes/${categoryId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetBrandsUsingGet2
   * @summary getBrands
   * @request GET:/api/v2/productpublication/brands
   */
  getBrandsUsingGet2 = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfBrandDTO, void>({
      path: `/api/v2/productpublication/brands`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetCategoriesUsingGet
   * @summary getCategories
   * @request GET:/api/v2/productpublication/categories/{categoryId}
   */
  getCategoriesUsingGet = (categoryId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfCategoryDTO, void>({
      path: `/api/v2/productpublication/categories/${categoryId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetCategoryTreeUsingGet1
   * @summary getCategoryTree
   * @request GET:/api/v2/productpublication/categoryTree
   */
  getCategoryTreeUsingGet1 = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfCategoryTree, void>({
      path: `/api/v2/productpublication/categoryTree`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetCommissionUsingGet
   * @summary getCommission
   * @request GET:/api/v2/productpublication/commission
   */
  getCommissionUsingGet = (
    query?: {
      /**
       * price
       * @format bigdecimal
       */
      price?: number;
      /** salesChannel */
      salesChannel?: "WEBSITE" | "BOUTIQUE_AND_WEBSITE" | "STOCK_AND_BOUTIQUE_AND_WEBSITE";
      /**
       * sellerId
       * @format int64
       */
      sellerId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfdouble, void>({
      path: `/api/v2/productpublication/commission`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetConversionUsingGet
   * @summary getConversion
   * @request GET:/api/v2/productpublication/conversion
   */
  getConversionUsingGet = (
    query?: {
      /**
       * priceWithoutCommission
       * @format bigdecimal
       */
      priceWithoutCommission?: number;
      /**
       * priceWithCommission
       * @format bigdecimal
       */
      priceWithCommission?: number;
      /** salesChannel */
      salesChannel?: "WEBSITE" | "BOUTIQUE_AND_WEBSITE" | "STOCK_AND_BOUTIQUE_AND_WEBSITE";
      /**
       * sellerId
       * @format int64
       */
      sellerId?: number;
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfConversion, void>({
      path: `/api/v2/productpublication/conversion`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name UpdateDefectImageCommentUsingPatch
   * @summary updateDefectImageComment
   * @request PATCH:/api/v2/productpublication/defectImageComment
   */
  updateDefectImageCommentUsingPatch = (
    query: {
      /**
       * productId
       * @format int64
       */
      productId: number;
      /**
       * imageOrder
       * @format int32
       */
      imageOrder: number;
      /** comment */
      comment: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfProductImageDTO, void>({
      path: `/api/v2/productpublication/defectImageComment`,
      method: "PATCH",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name DeleteDefectImageUsingPost
   * @summary deleteDefectImage
   * @request POST:/api/v2/productpublication/deleteDefectImage
   */
  deleteDefectImageUsingPost = (
    query: {
      /**
       * productId
       * @format int64
       */
      productId: number;
      /**
       * imageId
       * @format int64
       */
      imageId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOflong, void>({
      path: `/api/v2/productpublication/deleteDefectImage`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name DeleteProductImageUsingPost
   * @summary deleteProductImage
   * @request POST:/api/v2/productpublication/deleteProductImage
   */
  deleteProductImageUsingPost = (
    query: {
      /**
       * productId
       * @format int64
       */
      productId: number;
      /**
       * imageId
       * @format int64
       */
      imageId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOflong, void>({
      path: `/api/v2/productpublication/deleteProductImage`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetDraftsUsingGet
   * @summary getDrafts
   * @request GET:/api/v2/productpublication/drafts
   */
  getDraftsUsingGet = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/productpublication/drafts`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetDraftsPageUsingGet
   * @summary getDraftsPage
   * @request GET:/api/v2/productpublication/draftsPage
   */
  getDraftsPageUsingGet = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/productpublication/draftsPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetPriceWithCommissionUsingGet
   * @summary getPriceWithCommission
   * @request GET:/api/v2/productpublication/getPriceWithCommission
   */
  getPriceWithCommissionUsingGet = (
    query: {
      /**
       * priceWithoutCommission
       * @format bigdecimal
       */
      priceWithoutCommission: number;
      /** salesChannel */
      salesChannel?: "WEBSITE" | "BOUTIQUE_AND_WEBSITE" | "STOCK_AND_BOUTIQUE_AND_WEBSITE";
      /**
       * sellerId
       * @format int64
       */
      sellerId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfbigdecimal, void>({
      path: `/api/v2/productpublication/getPriceWithCommission`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetPriceWithoutCommissionUsingGet
   * @summary getPriceWithoutCommission
   * @request GET:/api/v2/productpublication/getPriceWithoutCommission
   */
  getPriceWithoutCommissionUsingGet = (
    query: {
      /**
       * priceWithCommission
       * @format bigdecimal
       */
      priceWithCommission: number;
      /** salesChannel */
      salesChannel?: "WEBSITE" | "BOUTIQUE_AND_WEBSITE" | "STOCK_AND_BOUTIQUE_AND_WEBSITE";
      /**
       * sellerId
       * @format int64
       */
      sellerId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfbigdecimal, void>({
      path: `/api/v2/productpublication/getPriceWithoutCommission`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetPriceWithoutCommissionInBaseCurrencyUsingGet
   * @summary getPriceWithoutCommissionInBaseCurrency
   * @request GET:/api/v2/productpublication/getPriceWithoutCommissionInBaseCurrency
   */
  getPriceWithoutCommissionInBaseCurrencyUsingGet = (
    query: {
      /**
       * currencyId
       * @format int64
       */
      currencyId?: number;
      /**
       * priceInCurrency
       * @format bigdecimal
       */
      priceInCurrency: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfbigdecimal, void>({
      path: `/api/v2/productpublication/getPriceWithoutCommissionInBaseCurrency`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetModeratingProductsUsingGet
   * @summary getModeratingProducts
   * @request GET:/api/v2/productpublication/moderationProducts
   */
  getModeratingProductsUsingGet = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/productpublication/moderationProducts`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetModeratingProductsPageUsingGet
   * @summary getModeratingProductsPage
   * @request GET:/api/v2/productpublication/moderationProductsPage
   */
  getModeratingProductsPageUsingGet = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /** sortAttribute */
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/productpublication/moderationProductsPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetProductUsingGet1
   * @summary getProduct
   * @request GET:/api/v2/productpublication/product/{productId}
   */
  getProductUsingGet1 = (
    productId: number,
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfProductDTO, void>({
      path: `/api/v2/productpublication/product/${productId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name DeleteProductUsingDelete
   * @summary deleteProduct
   * @request DELETE:/api/v2/productpublication/product/{productId}
   */
  deleteProductUsingDelete = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOflong, void>({
      path: `/api/v2/productpublication/product/${productId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetProductsUsingGet
   * @summary getProducts
   * @request GET:/api/v2/productpublication/products
   */
  getProductsUsingGet = (
    query?: {
      /** productStates */
      productStates?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/productpublication/products`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetProductsCountUsingGet
   * @summary getProductsCount
   * @request GET:/api/v2/productpublication/productsCount
   */
  getProductsCountUsingGet = (
    query?: {
      /** productState */
      productState?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfint, void>({
      path: `/api/v2/productpublication/productsCount`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetProductsCountsUsingGet
   * @summary getProductsCounts
   * @request GET:/api/v2/productpublication/productsCounts
   */
  getProductsCountsUsingGet = (
    query?: {
      /** productStates */
      productStates?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfMapOfstringAndint, void>({
      path: `/api/v2/productpublication/productsCounts`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetProductsPageUsingGet
   * @summary getProductsPage
   * @request GET:/api/v2/productpublication/productsPage
   */
  getProductsPageUsingGet = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /** productStates */
      productStates?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
      /** sortAttribute */
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** storeCode */
      storeCode?: string;
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/productpublication/productsPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetIntegrationLiteProductsPageUsingGet
   * @summary getIntegrationLiteProductsPage
   * @request GET:/api/v2/productpublication/integrationLiteProductsPage
   */
  getIntegrationLiteProductsPageUsingGet = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /** productStates */
      productStates?:
        | "BANED"
        | "DELETED"
        | "DRAFT"
        | "HIDDEN"
        | "NEED_MODERATION"
        | "NEED_RETOUCH"
        | "PUBLISHED"
        | "REJECTED"
        | "RETOUCH_DONE"
        | "SECOND_EDITION"
        | "SOLD";
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTOIntegrationLite, void>({
      path: `/api/v2/productpublication/integrationLiteProductsPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name PublishUsingPost
   * @summary publish
   * @request POST:/api/v2/productpublication/publish
   */
  publishUsingPost = (data: ProductDTOReq, params: RequestParams = {}) =>
    this.request<Api2ResponseOflong, void>({
      path: `/api/v2/productpublication/publish`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name PublishAndGetResultUsingPost
   * @summary publishAndGetResult
   * @request POST:/api/v2/productpublication/publishAndGetResult
   */
  publishAndGetResultUsingPost = (data: ProductDTOReq, params: RequestParams = {}) =>
    this.request<Api2ResponseOfProductDTO, void>({
      path: `/api/v2/productpublication/publishAndGetResult`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetRejectsUsingGet
   * @summary getRejects
   * @request GET:/api/v2/productpublication/rejects
   */
  getRejectsUsingGet = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/productpublication/rejects`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetRejectsPageUsingGet
   * @summary getRejectsPage
   * @request GET:/api/v2/productpublication/rejectsPage
   */
  getRejectsPageUsingGet = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /** sortAttribute */
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/productpublication/rejectsPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetProductPhotoSamplesUsingGet
   * @summary getProductPhotoSamples
   * @request GET:/api/v2/productpublication/samples/{categoryId}
   */
  getProductPhotoSamplesUsingGet = (categoryId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfProductImageDTO, void>({
      path: `/api/v2/productpublication/samples/${categoryId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetSecondEditionProductsUsingGet
   * @summary getSecondEditionProducts
   * @request GET:/api/v2/productpublication/secondEditionProducts
   */
  getSecondEditionProductsUsingGet = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfProductDTO, void>({
      path: `/api/v2/productpublication/secondEditionProducts`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetSecondEditionProductsPageUsingGet
   * @summary getSecondEditionProductsPage
   * @request GET:/api/v2/productpublication/secondEditionProductsPage
   */
  getSecondEditionProductsPageUsingGet = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /** sortAttribute */
      sortAttribute?:
        | "CHANGE_TIME"
        | "CHANGE_TIME_DESC"
        | "DISCOUNT"
        | "DISCOUNT_DESC"
        | "ID"
        | "ID_DESC"
        | "IS_OUR_CHOICE_DESC_PUBLISH_TIME_DESC"
        | "PRICE"
        | "PRICE_DESC"
        | "PRODUCT_STATE_TIME"
        | "PRODUCT_STATE_TIME_DESC"
        | "PUBLISH_TIME"
        | "PUBLISH_TIME_DESC";
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, void>({
      path: `/api/v2/productpublication/secondEditionProductsPage`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetSizesUsingGet1
   * @summary getSizes
   * @request GET:/api/v2/productpublication/sizes/{categoryId}
   */
  getSizesUsingGet1 = (categoryId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfSizeTypeDTO1, void>({
      path: `/api/v2/productpublication/sizes/${categoryId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetPriceWithoutCommissionUsingGet2
   * @summary getPriceWithoutCommission
   * @request GET:/api/v2/productpublication/step/{productId}
   */
  getPriceWithoutCommissionUsingGet2 = (productId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfStepInfo, void>({
      path: `/api/v2/productpublication/step/${productId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetPriceWithoutCommissionUsingGet1
   * @summary getPriceWithoutCommission
   * @request GET:/api/v2/productpublication/steps
   */
  getPriceWithoutCommissionUsingGet1 = (
    query: {
      /**
       * ids
       * @format int64
       */
      ids: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfStepInfo, void>({
      path: `/api/v2/productpublication/steps`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name UploadDefectImageUsingPost
   * @summary uploadDefectImage
   * @request POST:/api/v2/productpublication/uploadDefectImage
   */
  uploadDefectImageUsingPost = (
    query: {
      /**
       * productId
       * @format int64
       */
      productId: number;
      /**
       * imageOrder
       * @format int32
       */
      imageOrder: number;
      /** comment */
      comment: string;
    },
    data: ImageUploadDTO,
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfProductImageDTO, void>({
      path: `/api/v2/productpublication/uploadDefectImage`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name UploadProductImageUsingPost
   * @summary uploadProductImage
   * @request POST:/api/v2/productpublication/uploadProductImage
   */
  uploadProductImageUsingPost = (
    query: {
      /**
       * productId
       * @format int64
       */
      productId: number;
      /**
       * imageOrder
       * @format int32
       */
      imageOrder: number;
    },
    data: ImageUploadDTO,
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfProductImageDTO, void>({
      path: `/api/v2/productpublication/uploadProductImage`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags product-publication-controller-api-v-2
   * @name GetProductConditionsUsingGet
   * @summary getProductConditions
   * @request GET:/api/v2/productpublication/product-conditions
   */
  getProductConditionsUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfProductConditionDTO1, void>({
      path: `/api/v2/productpublication/product-conditions`,
      method: "GET",
      ...params,
    });
}
