import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  const isEnabled = !process.dev;

  useHead({
    script: isEnabled ? [
      {
        children: `
          mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
          mindbox.queue = mindbox.queue || [];
          mindbox('create');
        `,
      },
      {
        src: 'https://api.mindbox.ru/scripts/v1/tracker.js',
        async: true,
      },
    ] : [],
  });
});
