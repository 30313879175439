import { useAccountService } from '~/providers/account';

export function useNotificationsStore() {
  const { fetchNotification } = useAccountService();

  const count = useState('notificationsStoreCount', () => 0);

  async function getNotificationsCount() {
    try {
      const info = await fetchNotification();

      count.value = info?.commentsNotReadNoCommentsNeedActionNotCompleted ?? 0;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    count,
    getNotificationsCount,
  };
}
