export type AnyObject = Record<string, string | number | object>

export enum AnalyticsEvents {
  Wishlist ='wishlist',
  Cart ='cart',
  ProductView ='product-view',
  CartAdd ='cart-add',
  CartRemove ='cart-remove',
  ProductBargain ='product-bargain',
  ProductFollow ='product-follow',
  ProductUnfollow ='product-unfollow',
  ProductFollowAuthorization ='product-follow-authorization',
  ProductLike ='product-like',
  ProductDislike ='product-dislike',
  ProductWish ='product-wish',
  ProductUnwish ='product-unwish',
  UserSubscribe = 'UserSubscribe',
  UserUnsubscribe = 'UserUnsubscribe',
  ProductLikeAuthorization ='product-like-authorization',
  ProductCommentAdd ='product-comment-add',
  ProductCommentAddAll ='product-comment-add-all',
  ProductSizeTableOpen = 'product-size-table-open',
  ProductAboutClick = 'ProductAboutClick',
  ProductInfoCompanyClick = 'ProductInfoCompanyClick',
  GoToLoginPage = 'go-to-login-page',

  CartView = 'cart-view',
  CartTabUnfinished = 'cart-tab-unfinished',
  CartTabReady = 'cart-tab-ready',
  UserClick = 'user-click',
  ProductClick = 'product-click',
  ProductChangeClick = 'product-change-click',
  ProductDeleteFromCart = 'product-delete-from-cart',
  OrderDeleteFromCart = 'order-delete-from-cart',
  CartCheckout = 'cart-checkout',
  AboutServiceClick = 'about-service-click',
  ShowAllClick = 'show-all-click',
  AddToCart = 'add_to_cart',
  ProductsBlockView = 'products-block-view',
  CartLoginView = 'cart-login-view',

  CheckoutView = 'checkout-view',
  CheckoutAddShippingInfo = 'checkout-add-shipping-info',
  CheckoutAddPaymentInfo = 'checkout-add-payment-info',
  Comment = 'comment',
  Promocode = 'promocode',
  PromocodeApplySuccess = 'couponApplySuccess',
  PromocodeApplyFailed = 'couponApplyFailed',
  CheckoutPay = 'checkout-pay',
  CheckoutCardView = 'checkout-card',
  CheckoutCardClick = 'checkout-card-click',
  CheckoutGoPayment = 'checkout-go-payment',
  CheckoutGoPaymentOtherCard = 'checkout-go-payment-other-card',
  CheckoutGoPaymentNoCards = 'checkout-go-payment-no-cards',
  CheckoutChangePaymentType = 'checkout-change-payment-type',
  CheckoutCompleteView = 'checkout-complete-view',
  CheckoutOrderCreated = 'checkout-order-created',
  CheckoutCompleteMain = 'checkout-complete-main',
  CheckoutCompleteWishlist = 'checkout-complete-wishlist',
  CheckoutThanksProductClick = 'checkout-thanks-product-click',
  CheckoutYandexSplit = 'checkout_split',

  DialogLoginTab = 'dialog-login-tab',
  DialogSignUpTab = 'dialog-signup-tab',
  DialogLoginRemember = 'dialog-login-remember',
  DialogLoginAccepted = 'dialog-login-accepted',
  EnterLogin = 'enter_login',
  Registration = 'registration',

  BrandClick = 'BrandClick',
  SellerClick = 'SellerClick',

  SliderNext = 'SliderNext',
  SliderPrev = 'SliderPrev',

  MenuLinkClick = 'MenuLinkClick',
  CatalogMobileView = 'CatalogMobileView',
  CatalogMobileLinkClick = 'CatalogMobileLinkClick',
  HeaderLinkClick = 'HeaderLinkClick',
  FiltersLinkClick = 'FiltersLinkClick',
  FiltersView = 'FiltersView',
  PageView = 'PageView',
  MainBlockView = 'MainBlockView',
  MainBlockClick = 'MainBlockClick',

  ClickEvent = 'ClickEvent',
  BlockView = 'BlockView',
  SwiperBlockView = 'SwiperBlockView',
  SwiperBlockClick = 'SwiperBlockClick',

  OCommunityBadgeView = 'OCommunityBadgeView',
  OCommunityBadgeClick = 'OCommunityBadgeClick',
  OCommunityDialogView = 'OCommunityDialogView',
  OCommunityDialogClose = 'OCommunityDialogClose',

  ExclusiveSelectionDetailsClick = 'ExclusiveSelectionDetailsClick',

  YandexSplitInfo = 'info_split',
  YandexSplitInfoClose = 'close',
  YandexSplitInfoBackToItem = 'back_to_item',

  UserFollow = 'UserFollow',
  UserUnfollow = 'UserUnfollow',

  ProductCommentSuccess = 'ProductCommentSuccess',
  ConciergeFormSubmit = 'ConciergeFormSubmit',
}

export enum AnalyticsPages {
  ProductPage = 'product_page',
  InfoSplit = 'info_split',
  CheckoutPay = 'checkout_pay',
  Checkout = 'checkout',
  Cart = 'cart',
  PayWeb = 'pay_web',
}

export interface AnalyticService {
  id?: string | number,
  addEvent: (name: AnalyticsEvents, data?: AnyObject) => Promise<void>,
}

export interface IEventOptions {
  view?: Record<string, any>
  productClick?: Record<string, any>
  showAll?: Record<string, any>
  sliderArrows?: Record<string, any>
}

export interface IOptions {
  chapter?: string
  id_chapter?: string
  item?: string
  action?: string
  chapter_index?: number
  segment_id?: number
  index?: number
  searchId?: string | null
  page?: string
  value?: string
}
