import {
  ProductFilter,
  BooleanFilter,
  SizeFilter,
  PriceFilter,
  CategoryTreeFilter,
  MultiListFilter,
  FilterValue,
} from '~/restAPI/data-contracts';

export function isCategoryTreeFilter(value?: ProductFilter | null): value is CategoryTreeFilter {
  return value?.type === 'CATEGORY_TREE';
}

export function isMultiListFilter(value?: ProductFilter | null): value is MultiListFilter {
  return value?.type === 'MULTI_LIST';
}

export function isSizeFilter(value?: ProductFilter | null): value is SizeFilter {
  return value?.type === 'SIZE';
}

export function isPriceFilter(value?: ProductFilter | null): value is PriceFilter {
  return value?.type === 'PRICE';
}

export function isBooleanFilter(value?: ProductFilter | null): value is BooleanFilter {
  return value?.type === 'BOOLEAN';
}

export function isArrayFilter(value?: FilterValue): value is (number[] | string[]) {
  return Array.isArray(value);
}
