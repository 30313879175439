/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfAddressEndpointAggregationResult,
  Api2ResponseOfAddressEndpointDTO,
  Api2ResponseOfCounterpartyDTO,
  Api2ResponseOfGroupedCart,
  Api2ResponseOfInitOrderResult,
  Api2ResponseOfOrderDTO,
  Api2ResponseOfString,
  OrderDTO1,
  ProductsSalesRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Cart<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name GetGroupedCartUsingGet
   * @summary getGroupedCart
   * @request GET:/api/v2/cart
   */
  getGroupedCartUsingGet = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
      /**
       * showSoldWithSimilar
       * @default false
       */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfGroupedCart, void>({
      path: `/api/v2/cart`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name CleanCartUsingDelete
   * @summary cleanCart
   * @request DELETE:/api/v2/cart
   */
  cleanCartUsingDelete = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfGroupedCart, void>({
      path: `/api/v2/cart`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name GetBuyerCounterpartyUsingGet
   * @summary getBuyerCounterparty
   * @request GET:/api/v2/cart/buyerCounterparty
   */
  getBuyerCounterpartyUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfCounterpartyDTO, void>({
      path: `/api/v2/cart/buyerCounterparty`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name SetBuyerCounterpartyUsingPut
   * @summary setBuyerCounterparty
   * @request PUT:/api/v2/cart/buyerCounterparty
   */
  setBuyerCounterpartyUsingPut = (
    query?: {
      /**
       * buyerCounterpartyId
       * @format int64
       */
      buyerCounterpartyId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfCounterpartyDTO, void>({
      path: `/api/v2/cart/buyerCounterparty`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name CheckPromoCodeUsingGet
   * @summary checkPromoCode
   * @request GET:/api/v2/cart/checkPromoCode
   */
  checkPromoCodeUsingGet = (
    query?: {
      /**
       * sellerId
       * @format int64
       */
      sellerId?: number;
      /** promoCode */
      promoCode?: string;
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfOrderDTO, void>({
      path: `/api/v2/cart/checkPromoCode`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-international-api-v-2
   * @name GetDeliveryAddressEndpointAggregationUsingGet
   * @summary getDeliveryAddressEndpointAggregation
   * @request GET:/api/v2/cart/deliveryAddressEndpointAggregation
   */
  getDeliveryAddressEndpointAggregationUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfAddressEndpointAggregationResult, void>({
      path: `/api/v2/cart/deliveryAddressEndpointAggregation`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-international-api-v-2
   * @name SetDeliveryAddressEndpointAggregationUsingPut
   * @summary setDeliveryAddressEndpointAggregation
   * @request PUT:/api/v2/cart/deliveryAddressEndpointAggregation
   */
  setDeliveryAddressEndpointAggregationUsingPut = (
    query?: {
      /**
       * deliveryAddressEndpointAggregationId
       * @format int64
       */
      deliveryAddressEndpointAggregationId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAddressEndpointAggregationResult, void>({
      path: `/api/v2/cart/deliveryAddressEndpointAggregation`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name GetDeliveryAddressEndpointUsingGet
   * @summary getDeliveryAddressEndpoint
   * @request GET:/api/v2/cart/deliveryAddressEndpoint
   */
  getDeliveryAddressEndpointUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfAddressEndpointDTO, void>({
      path: `/api/v2/cart/deliveryAddressEndpoint`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name SetDeliveryAddressEndpointUsingPut
   * @summary setDeliveryAddressEndpoint
   * @request PUT:/api/v2/cart/deliveryAddressEndpoint
   */
  setDeliveryAddressEndpointUsingPut = (
    query?: {
      /**
       * deliveryAddressEndpointId
       * @format int64
       */
      deliveryAddressEndpointId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAddressEndpointDTO, void>({
      path: `/api/v2/cart/deliveryAddressEndpoint`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name GetDeliveryCommentUsingGet
   * @summary getDeliveryComment
   * @request GET:/api/v2/cart/deliveryComment
   */
  getDeliveryCommentUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfString, void>({
      path: `/api/v2/cart/deliveryComment`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name SetDeliveryCommentUsingPut
   * @summary setDeliveryComment
   * @request PUT:/api/v2/cart/deliveryComment
   */
  setDeliveryCommentUsingPut = (
    query?: {
      /** comment */
      comment?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfString, void>({
      path: `/api/v2/cart/deliveryComment`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name InitHoldUsingPost
   * @summary initHold
   * @request POST:/api/v2/cart/hold
   */
  initHoldUsingPost = (
    query?: {
      /**
       * sellerId
       * @format int64
       */
      sellerId?: number;
      /** promoCode */
      promoCode?: string;
      /**
       * paymentBuyerCounterpartyId
       * @format int64
       */
      paymentBuyerCounterpartyId?: number;
      /** fromMobile */
      fromMobile?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfInitOrderResult, void>({
      path: `/api/v2/cart/hold`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name InitHoldV2UsingPost
   * @summary initHoldV2
   * @request POST:/api/v2/cart/holdv2
   */
  initHoldV2UsingPost = (
    query?: {
      /**
       * sellerId
       * @format int64
       */
      sellerId?: number;
      /** promoCode */
      promoCode?: string;
      /**
       * paymentBuyerCounterpartyId
       * @format int64
       */
      paymentBuyerCounterpartyId?: number;
      /** fromMobile */
      fromMobile?: boolean;
      /** currencyCode */
      currencyCode?: string;
      /** paymentSystem */
      paymentSystem?: string;
      /** Payment option type */
      type?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfInitOrderResult, void>({
      path: `/api/v2/cart/holdv2`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name CreateOrderWithSaleRequestUsingPost
   * @summary createOrderWithSaleRequest
   * @request POST:/api/v2/cart/createOrderWithSaleRequest
   */
  createOrderWithSaleRequestUsingPost = (data: ProductsSalesRequest, params: RequestParams = {}) =>
    this.request<OrderDTO1, void>({
      path: `/api/v2/cart/createOrderWithSaleRequest`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name AddItemUsingPut
   * @summary addItem
   * @request PUT:/api/v2/cart/items
   */
  addItemUsingPut = (
    query?: {
      /**
       * productId
       * @format int64
       */
      productId?: number;
      /**
       * sizeId
       * @format int64
       */
      sizeId?: number;
      /**
       * count
       * @format int32
       */
      count?: number;
      /** currencyCode */
      currencyCode?: string;
      /**
       * showSoldWithSimilar
       * @default false
       */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfGroupedCart, void>({
      path: `/api/v2/cart/items`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name ChangePositionSizeOrCountUsingPatch
   * @summary changePositionSizeOrCount
   * @request PATCH:/api/v2/cart/items
   */
  changePositionSizeOrCountUsingPatch = (
    query?: {
      /**
       * orderPositionId
       * @format int64
       */
      orderPositionId?: number;
      /**
       * sizeId
       * @format int64
       */
      sizeId?: number;
      /**
       * count
       * @format int32
       */
      count?: number;
      /** currencyCode */
      currencyCode?: string;
      /**
       * showSoldWithSimilar
       * @default false
       */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfGroupedCart, void>({
      path: `/api/v2/cart/items`,
      method: "PATCH",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name DeletePositionUsingDelete
   * @summary deletePosition
   * @request DELETE:/api/v2/cart/items/{orderPositionId}
   */
  deletePositionUsingDelete = (
    orderPositionId: number,
    query?: {
      /** currencyCode */
      currencyCode?: string;
      /**
       * showSoldWithSimilar
       * @default false
       */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfGroupedCart, void>({
      path: `/api/v2/cart/items/${orderPositionId}`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cart-controller-api-v-2
   * @name GetSimpleCartUsingGet
   * @summary getSimpleCart
   * @request GET:/api/v2/cart/simple
   */
  getSimpleCartUsingGet = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
      /**
       * showSoldWithSimilar
       * @default false
       */
      showSoldWithSimilar?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfOrderDTO, void>({
      path: `/api/v2/cart/simple`,
      method: "GET",
      query: query,
      ...params,
    });
}
