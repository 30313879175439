import { RouteLocation } from 'vue-router';
import { useApi } from '~/restAPI';
import { Account } from '~/restAPI/Account';
import { Productpublication } from '~/restAPI/Productpublication';
import { Notifications } from '~/restAPI/Notifications';
import { Cart } from '~/restAPI/Cart';
import { Catalog } from '~/restAPI/Catalog';
import { Publicprofile } from '~/restAPI/Publicprofile';
import { Settings } from '~/restAPI/Settings';
import type { ProductDTOReq, AddressEndpointDTO, CounterpartyDTO } from '~/restAPI/data-contracts';
import { mappedQueryParams } from '~/utils/api';

export function useAccountService() {
  const { currentCountry } = useInternational();
  const { createApiInstance } = useApi();
  const accountApi = createApiInstance(Account);
  const catalogApi = createApiInstance(Catalog);
  const publicProfileApi = createApiInstance(Publicprofile);
  const settingsApi = createApiInstance(Settings);
  const productPublicationApi = createApiInstance(Productpublication);
  const notificationApi = createApiInstance(Notifications);
  const cartApi = createApiInstance(Cart);

  function auth(email: string, password: string) {
    return accountApi.rawauthUsingPost({
      rawEmail: email,
      rawPassword: password,
      // rawEmail: 'isadykov@oskelly.ru',
      // rawPassword: 'adminadmin',
      // rawEmail: 'ivan.belykh@gmail.com',
      // rawPassword: '111',
    }, { format: 'json' });
  }

  function register(data: {
    registerConfirmPassword: string;
    registerEmail: string;
    registerNickname: string;
    registerPassword: string;
    registerPhone?: string;
    subscriptionApprove?: boolean;
  }) {
    return accountApi.registerUsingPost(data, { format: 'json' })
      .then((res) => res.data.data);
  }

  function getAccount() {
    return accountApi.getAccountUsingGet({ format: 'json' })
      .then((data) => data.data.data);
  }

  function getSettings() {
    return settingsApi.getSettingsUsingGet1({
      countryId: currentCountry.value.id,
    }, { format: 'json' })
      .then((data) => data.data.data);
  }

  function getAccountInfo() {
    return accountApi.getAccountUsingGet1(undefined, { format: 'json' })
      .then((data) => data.data.data);
  }

  function getPublicProfile(userId: number) {
    return publicProfileApi.getProfileUsingGet(userId, undefined, { format: 'json' })
      .then((data) => data?.data?.data);
  }

  function getFollowers(query: { page: number; pageSize: number; userId: number; query: string }) {
    return publicProfileApi.getFollowersUsingGet1(query, { format: 'json' })
      .then((data) => data?.data?.data);
  }

  function fetchProductsForAccount(sellerId: number, page: number, route: RouteLocation, pageLength = 60) {
    return catalogApi.getCatalogUsingGet({
      ...mappedQueryParams(route),
      sellerId,
      page,
      pageLength,
    }, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  function fetchModerationProductsPage(sellerId: number, page: number, route: RouteLocation) {
    return productPublicationApi.getModeratingProductsPageUsingGet({
      ...mappedQueryParams(route),
      page,
      pageSize: 60,
      currencyCode: currentCountry.value?.currency?.isoCode,
    }, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  function fetchSecondEditionProductsPage(page: number, route: RouteLocation) {
    return productPublicationApi.getSecondEditionProductsPageUsingGet({
      ...mappedQueryParams(route),
      page,
      pageSize: 60,
      currencyCode: currentCountry.value?.currency?.isoCode,
    }, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  function fetchDraftsProductsPage(sellerId: number, page: number, route: RouteLocation) {
    return productPublicationApi.getDraftsPageUsingGet({
      ...mappedQueryParams(route),
      page,
      pageSize: 60,
      currencyCode: currentCountry.value?.currency?.isoCode,
    }, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  function fetchRejectProductsPage(sellerId: number, page: number, route: RouteLocation) {
    return productPublicationApi.getRejectsPageUsingGet({
      ...mappedQueryParams(route),
      page,
      pageSize: 60,
      currencyCode: currentCountry.value?.currency?.isoCode,
    }, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  async function fetchProductsCounts() {
    return productPublicationApi.getProductsCountsUsingGet(undefined, { format: 'json' })
      .then((data) => data.data.data);
  }

  function patchProduct(productDTO: ProductDTOReq) {
    return productPublicationApi.publishUsingPost(productDTO, { format: 'json' })
      .then((data) => data.data.data);
  }

  function fetchSubscribe({ emailAddress, sex }:{ emailAddress: string, sex: 'FEMALE' | 'MALE' }) {
    return accountApi.emailSubscribeUsingPost({ emailAddress, sex }, { format: 'json' })
      .then((data) => data.data);
  }

  function fetchNotification() {
    return notificationApi.getNotificationBubblesUsingGet(
      { currencyCode: currentCountry.value?.currency?.isoCode },
      { format: 'json' },
    ).then((data) => data!.data!.data!);
  }

  function fetchCart() {
    return cartApi.getGroupedCartUsingGet(
      { currencyCode: currentCountry.value?.currency?.isoCode },
      { format: 'json' },
    ).then((data) => data!.data!.data!);
  }
  function deleteProduct(productId: number) {
    return productPublicationApi.deleteProductUsingDelete(productId, { format: 'json' })
      .then((data) => data.data.data);
  }

  function getNoCommentsNotifications(query?: { page?: number, pageSize?: number }, cancelToken?: number | string) {
    return notificationApi.getNoCommentsNotificationsUsingGet(query, { format: 'json', cancelToken })
      .then((data) => data!.data!.data!);
  }

  function getCommentsNotifications(query?: { page?: number, pageSize?: number }, cancelToken?: number | string) {
    return notificationApi.getCommentsNotificationsUsingGet(query, { format: 'json', cancelToken })
      .then((data) => data!.data!.data!);
  }

  function pathReadNotifications(id: number) {
    return notificationApi.readNotificationUsingPatch(id, { format: 'json' })
      .then((data) => data.data.data);
  }

  function toggleFollowing(id: number) {
    return publicProfileApi.toggleFollowingUsingPost({ userId: id }, { format: 'json' })
      .then((data) => data.data.data);
  }

  function saveAddressEndpoint(addressEndpoint: AddressEndpointDTO) {
    return accountApi.saveAddressEndpointUsingPut(addressEndpoint, { format: 'json' })
      .then((data) => data.data.data);
  }

  function deleteAddressEndpoint(addressEndpointId: number) {
    return accountApi.deleteAddressEndpointUsingDelete({ addressEndpointId }, { format: 'json' })
      .then((data) => data.data.data);
  }

  function deleteCounterparty(counterpartyId: number) {
    return accountApi.saveCounterpartyUsingDelete({ counterpartyId })
      .then((data) => data.data.data);
  }

  function bindCardUsingPut(redirect: string) {
    return accountApi.bindCardUsingPut({ redirect })
      .then((data) => data.data.data);
  }

  function saveCounterparty(value: CounterpartyDTO) {
    return accountApi.saveCounterpartyUsingPut(value)
      .then((data) => data.data.data);
  }

  return {
    auth,
    register,
    getAccount,
    getPublicProfile,
    getSettings,
    fetchProductsForAccount,
    fetchModerationProductsPage,
    fetchSecondEditionProductsPage,
    fetchDraftsProductsPage,
    fetchRejectProductsPage,
    fetchProductsCounts,
    patchProduct,
    fetchSubscribe,
    deleteProduct,
    fetchNotification,
    fetchCart,
    getNoCommentsNotifications,
    getCommentsNotifications,
    pathReadNotifications,
    toggleFollowing,
    getFollowers,
    saveAddressEndpoint,
    deleteAddressEndpoint,
    getAccountInfo,
    notificationApi,
    deleteCounterparty,
    bindCardUsingPut,
    saveCounterparty,
  };
}
