import { browserTracingIntegration, init } from '@sentry/vue';
import { replayIntegration } from '@sentry/replay';

const ignoreExceptions = new Set([
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
]);

export default defineNuxtPlugin((nuxtApp) => {
  const { public: { sentry, RELEASE_VERSION } } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: RELEASE_VERSION,
    beforeSend(event, hint) {
      try {
        if (event.exception?.values?.some(({ value }) => value && ignoreExceptions.has(value))) {
          return null;
        }
      } catch (e) {
        console.error(e);
      }

      return event;
    },
    integrations: [
      browserTracingIntegration(),
      replayIntegration(),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.05, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ 'localhost', 'https://oskelly.ru' ],

    replaysSessionSampleRate: 0.05, // Change in prod
    replaysOnErrorSampleRate: 0.05, // Change in prod if necessary
  });
});
