export enum CartTab {
    Ready = 'Ready',
    Unfinished = 'Unfinished'
}

export enum ECartInfo {
    Quality = 'Quality',
    Delivery = 'Delivery',
    Return = 'Return',
    Pack = 'Pack',
    Security = 'Security',
    Help = 'Help'
}

interface IResponse extends Response {
    data: {
        errorData?: object
    }
}
