import {
  RouteLocation, LocationQuery,
} from 'vue-router';

import { FilterValue, PriceValue } from '~/restAPI/data-contracts';

import type { TFilters } from '~/types/filters';

function replaceParam(oldParam: string, newParam: string, obj: TFilters | LocationQuery) {
  if (!obj[oldParam]) return;

  // eslint-disable-next-line no-param-reassign
  obj[newParam] = obj[oldParam];
  // eslint-disable-next-line no-param-reassign
  delete obj[oldParam];
}

function replaceNumberArrayParam(obj: TFilters, newParam: string, oldParam?: string) {
  if (oldParam === 'isPro' && obj[oldParam]) {
    // eslint-disable-next-line no-param-reassign
    obj[newParam] = obj[newParam] === 'false' ? [2] : [1];
    // eslint-disable-next-line no-param-reassign
    delete obj[oldParam];
    return;
  }

  if (oldParam) {
    replaceParam(oldParam, newParam, obj);
  }

  let newParamValue = obj[newParam];

  if (newParam === 'sellerType') {
    if (newParamValue === 'simple') newParamValue = [2];
    if (newParamValue === 'pro') newParamValue = [1];
  }

  if (newParamValue && typeof newParamValue === 'string') {
    // eslint-disable-next-line no-param-reassign
    obj[newParam] = newParamValue.split(',')
      .map((i: string) => Number(i))
      .filter((i) => !Number.isNaN(i));
  } else if (newParamValue && Array.isArray(newParamValue)) {
    // eslint-disable-next-line no-param-reassign
    obj[newParam] = newParamValue
      .map((i: string | number) => Number(i))
      .filter((i) => !Number.isNaN(i));
  }
}

function replaceBooleanParam(oldParam: string, newParam: string, obj: TFilters | LocationQuery) {
  replaceParam(oldParam, newParam, obj);

  const newParamValue = obj[newParam];

  if (newParamValue && typeof newParamValue === 'string') {
    // eslint-disable-next-line no-param-reassign
    obj[newParam] = newParamValue === '1' || newParamValue.toLowerCase() === 'true';
  }
}

function removeParam(param: string, obj: TFilters | LocationQuery) {
  if (obj[param]) {
    // eslint-disable-next-line no-param-reassign
    delete obj[param];
  }
}

function replaceAllBooleanParams(result: TFilters | LocationQuery) {
  replaceBooleanParam('ourChoiceCategory', 'oskellyChoice', result);
  replaceBooleanParam('hasOurChoice', 'oskellyChoice', result);
  replaceBooleanParam('ourChoice', 'oskellyChoice', result);
  replaceBooleanParam('isVintage', 'vintage', result);
  replaceBooleanParam('isStreetwear', 'streetwear', result);
  replaceBooleanParam('userLikedBrands', 'likedBrands', result);
  replaceBooleanParam('isNewCollection', 'newCollection', result);
  replaceBooleanParam('isOnSale', 'sale', result);
  replaceBooleanParam('isSale', 'sale', result);
  replaceBooleanParam('useUserSex', 'userSex', result);
  replaceBooleanParam('isAtOffice', 'inStock', result);
  replaceBooleanParam('newProducts', 'withTag', result);

  Object.keys(result).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    if (result[key] === 'true') result[key] = true;
  });
}

const params = [
  'category',
  'inStock',
  'oskellyChoice',
  'streetwear',
  'condition',
  'state',
  'attributeValues',
  'size',
  'sizeType',
  'product',
  'likedBrands',
  'newCollection',
  'sale',
  'vintage',
  'userSex',
  'startPrice',
  'endPrice',
  'sellerType',
  'seller',
  'brand',
  'model',
  'newCollection',
  'withTag',
  'price',
  'brandNew',
  'resale',
  'boutiqueLocationTag',
  'exclusiveSelection',
];

export function mappedParams(data?: Partial<LocationQuery>): TFilters {
  const result = { ...data } as TFilters;

  removeParam('sort', result);
  removeParam('order', result);
  removeParam('sorting', result);
  removeParam('page', result);

  replaceAllBooleanParams(result);

  replaceNumberArrayParam(result, 'category', 'categoriesIds');
  replaceNumberArrayParam(result, 'product', 'productsIds');
  replaceNumberArrayParam(result, 'brand', 'interestingBrands');
  replaceNumberArrayParam(result, 'condition', 'productCondition');
  replaceNumberArrayParam(result, 'sellerType', 'isPro');
  replaceNumberArrayParam(result, 'model', 'models');
  replaceNumberArrayParam(result, 'attributeValues', 'filter');
  replaceNumberArrayParam(result, 'condition', 'interestingConditions');
  replaceNumberArrayParam(result, 'seller', 'sellerId');
  replaceNumberArrayParam(result, 'size');
  replaceNumberArrayParam(result, 'boutiqueLocationTag');

  if (result.startPrice || result.endPrice) {
    result.price = {};

    if (result.startPrice) {
      result.price.lower = Number.isNaN(Number(result.startPrice)) ? undefined : Number(result.startPrice);
    }

    if (result.endPrice) {
      result.price.upper = Number.isNaN(Number(result.endPrice)) ? undefined : Number(result.endPrice);
    }

    delete result.startPrice;
    delete result.endPrice;
  }

  Object.keys(result).forEach((key) => {
    if (key.startsWith('attribute_')) {
      replaceNumberArrayParam(result, key);
    }
  });

  if (result.category && Array.isArray(result.category)) {
    result.category = result.category.filter((i) => i !== 1) as number[];

    if (!result.category.length) delete result.category;
  }

  Object.keys(result).forEach((key) => {
    if (!params.includes(key) && !key.startsWith('attribute_')) {
      delete result[key];
    }
  });

  return result;
}

export function mappedFiltersToQuery(
  filters: Record<string, FilterValue | undefined>,
): LocationQuery {
  const query = Object.keys(filters).reduce((result, key) => {
    const value = filters[key];

    if (isArrayFilter(value)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value.map((el) => String(el));
    } else if ((value as PriceValue)?.lower || (value as PriceValue)?.upper) {
      if ((value as PriceValue)?.lower) {
        // eslint-disable-next-line no-param-reassign
        result.startPrice = String((value as PriceValue).lower);
      }

      if ((value as PriceValue)?.upper) {
        // eslint-disable-next-line no-param-reassign
        result.endPrice = String((value as PriceValue).upper);
      }
    } else if (value) {
      // eslint-disable-next-line no-param-reassign
      result[key] = String(value);
    }

    return result;
  }, {} as LocationQuery);

  return query;
}

export function mappedQueryParams(route: RouteLocation) {
  const query = { ...route.query } as TFilters;

  replaceParam('sort', 'order', query);
  replaceParam('brand', 'interestingBrands', query);
  replaceParam('models', 'interestingProductModels', query);
  replaceParam('size', 'interestingSizes', query);
  replaceParam('filter', 'interestingAttributeValues', query);
  replaceParam('productCondition', 'interestingConditions', query);
  replaceParam('newCollection', 'isNewCollection', query);
  replaceParam('atOffice', 'isAtOffice', query);
  replaceParam('vintage', 'isVintage', query);

  return query;
}

export function isAbortError(err:unknown) {
  return err instanceof DOMException && err.name === 'AbortError';
}
