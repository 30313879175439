import { useApi } from '~/restAPI';
import { Analytics } from '~/restAPI/Analytics';
import { OskellyAnalyticsEvent } from '~/types/analytics/oskellyAnalytics';

export function useAnalyticsProvider() {
  const config = useRuntimeConfig();
  const { createApiInstance } = useApi(config.public.ANALYTICS_API_PATH);
  const analyticsApi = createApiInstance(Analytics);

  function saveAnalyticsEvent(event: OskellyAnalyticsEvent) {
    return analyticsApi.saveEvent(event, { format: 'json' });
  }

  return {
    analyticsApi,
    saveAnalyticsEvent,
  };
}
