import { CountryDTO } from '~/restAPI/data-contracts';

const RUSSIA: CountryDTO = {
  id: 191,
  name: 'Россия',
  isoCodeAlpha2: 'RU',
  imageUrl: 'https://static.oskelly.ru/imgs/countries/rus.png',
  currency: {
    id: 1,
    name: 'Russian ruble',
    sign: '₽',
    isoCode: 'RUB',
    isoNumber: 643,
    selectedByDefault: false,
    active: true,
    base: true,
  },
  uiCurrencyCode: 'RUB',
  requireZipcode: true,
  environment: 'RU',
};

const INTERNATIONAL: CountryDTO = {
  id: 2,
  name: 'United Arab Emirates',
  isoCodeAlpha2: 'AE',
  imageUrl: 'https://international.oskelly.tech/imgs/countries/are.png',
  currency: {
    id: 4,
    name: 'United Arab Emirates dirham',
    sign: 'AED',
    isoCode: 'AED',
    isoNumber: 784,
    selectedByDefault: true,
    active: true,
    base: true,
  },
  uiCurrencyCode: 'AED',
  requireZipcode: false,
  environment: 'INT',
};

export const COUNTRIES = {
  RUSSIA,
  INTERNATIONAL,
};
