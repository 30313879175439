<template>
  <div class="error wrapper">
    <div class="error__title playfair">
      {{ errorText.title }}
    </div>
    <div class="error__description">
      {{ errorText.message }}
    </div>
    <div
      class="error__buttons"
    >
      <a
        v-for="link in catalogLinks"
        :key="link.href"
        :href="link.href"
      >{{ link.text }}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
const error = useError();
const { t } = useI18n();

const errorText = {
  title: '',
  message: '',
};
const isNotFound = error.value?.statusCode === 404;

console.error(error.value?.message, error.value?.stack);

if (isNotFound) {
  errorText.title = t('components.ui.errorBlock.title');
  errorText.message = t('components.ui.errorBlock.text');
} else {
  errorText.title = t('error.title');
  errorText.message = '';
}

const catalogLinks = [
  {
    text: t('uikit.errorBlock.catalogLinks.men'),
    href: '/men',
  },
  {
    text: t('uikit.errorBlock.catalogLinks.women'),
    href: '/women',
  },
  {
    text: t('uikit.errorBlock.catalogLinks.kids'),
    href: '/kids',
  },
  {
    text: t('uikit.errorBlock.catalogLinks.lifestyle'),
    href: '/lifestyle',
  },
];

</script>

<style scoped lang="scss">
@tailwind components;

@layer components {
  .error {
    @apply px-4 text-center flex items-center justify-center flex-col h-full;
    .error__title {
      @apply md:text-[34px] md:leading-[44px] text-[22px] leading-[28px] md:tracking-[2px] tracking-[1px] uppercase;
    }
    .error__description {
      @apply mx-auto mt-[15px] md:text-sm text-xs md:leading-[22px] leading-5 max-w-[560px] text-[#828282];
    }
    .error__buttons {
      @apply flex items-center justify-center mt-[18px] md:flex-row flex-col w-full;
      a {
        @apply text-center border-[1px] border-[rgba(189,189,189,0.6)] rounded-sm text-sm leading-none transition duration-300;
        @apply block py-[15px] px-[5px] md:w-[140px] w-full m-[13px] bg-transparent hover:bg-[rgba(189,189,189,0.6)];
      }
    }
  }
}

</style>
