export default defineNuxtPlugin((nuxtApp) => {
  // eslint-disable-next-line no-param-reassign
  nuxtApp.vueApp.config.errorHandler = (err, instance, info) => {
    if (err?.stack) {
      console.error(JSON.stringify(err, Object.getOwnPropertyNames(err)));
    } else {
      console.error(err);
    }
  };
});
