import { HTTPError, NormalizedOptions } from 'ky';

interface IResponse extends Response {
  data?: {
    errorData?: object
    humanMessage?: string
  }
}

interface IErrorData {
  link?: string
}

interface IData {
  errorData?: IErrorData
  humanMessage?: string
  message?: string
  file?: string
}

export class CustomHTTPError extends HTTPError {
  public statusCode: number;
  public data?: IData;

  constructor(public response: IResponse, request: Request, options: NormalizedOptions) {
    super(response, request, options);

    this.message = `${this.message}; url = ${request.url}`;
    this.statusCode = this.response.status;
    this.data = this.response.data;
  }
}

export function isCustomHTTPError(value: unknown): value is CustomHTTPError {
  return value instanceof CustomHTTPError;
}
