export function useUiBreakpoints() {
  const breakpoints = useBreakpoints({
    sm: 580,
    md: 767,
    lg: 1024,
    xl: 1200,
  });

  const isMounted = useMounted();

  const result = {
    isMounted,
    breakpoints,
    mobile: breakpoints.smaller('sm'),
    laptopS: breakpoints.between('sm', 'md'),
    laptopM: breakpoints.between('md', 'lg'),
    laptop: breakpoints.between('sm', 'lg'),
    desktopS: breakpoints.between('lg', 'xl'),
    desktopM: breakpoints.xl,
    desktop: breakpoints.greater('lg'),
  };
  const isDesktop = computed(() => result.desktop.value || result.desktopS.value || result.desktopM.value);
  const isMobile = computed(() => !isDesktop.value);

  return {
    ...result,
    isDesktop,
    isMobile,
  };
}
