/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfPageOfProductDTO,
  ApiV2ResponseOfItemsCount,
  ApiV2ResponseOfProductFilter,
  ApiV2ResponseOfProductFilters,
  ProductFilterInfoRequest,
  ProductFilterItemsRequest,
  ProductFilterRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Products<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Получение доступных фильтров (+ опционально товаров) в зависимости от заданной фильтрации
   *
   * @name PostApiV2ProductsFilter
   * @summary getAvailableFilters
   * @request POST:/api/v2/products/filter
   */
  postApiV2ProductsFilter = (data: ProductFilterRequest, params: RequestParams = {}) =>
    this.request<ApiV2ResponseOfProductFilters, any>({
      path: `/api/v2/products/filter`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsFilterInfo
   * @summary getAvailableFilterInfo
   * @request POST:/api/v2/products/filter/info
   */
  postApiV2ProductsFilterInfo = (
    data: ProductFilterInfoRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2ResponseOfProductFilter, any>({
      path: `/api/v2/products/filter/info`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsFilterItems
   * @summary getItems
   * @request POST:/api/v2/products/filter/items
   */
  postApiV2ProductsFilterItems = (
    data: ProductFilterItemsRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfProductDTO, any>({
      path: `/api/v2/products/filter/items`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostApiV2ProductsFilterCount
   * @summary getItemsCount
   * @request POST:/api/v2/products/filter/count
   */
  postApiV2ProductsFilterCount = (
    data: ProductFilterItemsRequest,
    query?: {
      /** Код фильтра */
      code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2ResponseOfItemsCount, any>({
      path: `/api/v2/products/filter/count`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
