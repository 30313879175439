import { useStorage } from '@vueuse/core';

import {
  PublicProfileDTO,
  AccountDTORes,
  UserAuthInfoDTO,
  Settings,
  PhoneNumberAuthorizationRequest,
} from '~/restAPI/data-contracts';
import { useAccountService } from '~/providers/account';

interface IAccount extends PublicProfileDTO, AccountDTORes{}

type TRegisterDialogData = PhoneNumberAuthorizationRequest & {
  onlyCountries?: string[]
}

type IAuthChapter = 'sale_request' | 'notification' | 'account' | 'wishlist' | 'product_request' |
  'product_comments' | 'product_like' | 'subscribe_user' | 'create_account';

interface IAuthOpenDialog {
  to?: string | (() => string)
  chapter?: IAuthChapter
}

export interface ISavedAccount {
  nickname: string
  email: string
  avatarPath?: string
  id?: string
}

export function useAccountStore() {
  const { isMounted } = useUiBreakpoints();

  const isAuth = useState('accountStoreIsAuth', () => false);
  const firstLoaded = useState('accountStoreFirstLoaded', () => false);
  const isLoading = useState('accountStoreIsLoading', () => false);
  const globalSettings = useState<Settings>('globalSettingsStoreAccount', () => ({}));
  const account = useState<IAccount>('accountStoreAccount', () => ({}));
  const accountInfo = useState<UserAuthInfoDTO>('accountStoreAccountInfo', () => ({}));
  const isAuthDialogOpen = useState('isAuthDialogOpen', () => false);
  const nextAuthLink = useState<(() => string) | string>('nextAuthLink', () => '');

  const isRegisterDialogOpen = useState('isRegisterDialogOpen', () => false);
  const registerDialogData = useState<TRegisterDialogData | null>('registerDialogData', () => null);

  const authDialogChapter = useState<IAuthChapter | undefined>('authDialogChapter', () => undefined);

  const isNewUser = useStorage<boolean | undefined>('oskIsNewUser', null);
  const savedAccounts = useStorage<ISavedAccount[] | undefined>('savedAccounts', []);

  const isProductModerator = computed(() => hasPermissions('PRODUCT_MODERATION'));
  /** Пользователи, которые делают заказы только для бутик. Для таких отключаем аналитику */
  const isSyntheticUser = computed(() => account.value?.id === 231441);

  const {
    getAccount: loadAccount,
    getPublicProfile,
    getAccountInfo,
    getSettings,
  } = useAccountService();

  async function getAccount() {
    isLoading.value = true;

    try {
      await getSettings().then((res) => {
        globalSettings.value = res ?? {};
      });

      // Если делать Promise.all бэк падает
      const data = await loadAccount();
      const info = await getAccountInfo();
      const publicProfile = data?.id ? await getPublicProfile(data.id) : {};

      account.value = {
        ...data,
        ...publicProfile,
      };

      accountInfo.value = info!;

      setAuthSuccess();
    } catch (error) {
      setAuthFail();
    } finally {
      isLoading.value = false;
      firstLoaded.value = true;
    }
  }

  function setAuthSuccess() {
    isAuth.value = true;
  }

  function setAuthFail() {
    isAuth.value = false;
  }

  function hasPermissions(permission: string) {
    return [
      'ADMIN',
      permission,
    ].some((p) => accountInfo.value.authorities?.includes(p));
  }

  function openAuthDialog(data?: IAuthOpenDialog) {
    if (!isMounted.value) return;

    authDialogChapter.value = data?.chapter;
    isAuthDialogOpen.value = true;

    nextAuthLink.value = data?.to ?? '';
  }

  return {
    isAuth,
    firstLoaded,
    isLoading,
    account,
    accountInfo,
    isNewUser,
    savedAccounts,
    globalSettings,
    getAccount,
    setAuthSuccess,
    setAuthFail,
    hasPermissions,
    isProductModerator,
    isSyntheticUser,
    isAuthDialogOpen,
    openAuthDialog,
    nextAuthLink,
    authDialogChapter,

    isRegisterDialogOpen,
    registerDialogData,
  };
}
