/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Api2ResponseOfSettings } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Settings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags settings-controller-api-v-2
   * @name GetSettingsUsingGet1
   * @summary getSettings
   * @request GET:/api/v2/settings
   */
  getSettingsUsingGet1 = (
    query?: {
      /**
       * Id страны
       * @format int64
       */
      countryId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfSettings, void>({
      path: `/api/v2/settings`,
      method: "GET",
      query: query,
      ...params,
    });
}
