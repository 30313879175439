/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfNotificationBubbles,
  Api2ResponseOfNotificationDTO,
  Api2ResponseOfPageOfNotificationDTO,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Notifications<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name GetNotificationsUsingGet
   * @summary getNotifications
   * @request GET:/api/v2/notifications
   */
  getNotificationsUsingGet = (
    query?: {
      exceptType?: string;
      isActionCompleted?: boolean;
      isRead?: boolean;
      needAction?: boolean;
      onlyType?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfNotificationDTO, void>({
      path: `/api/v2/notifications`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name GetNotificationBubblesUsingGet
   * @summary getNotificationBubbles
   * @request GET:/api/v2/notifications/bubbles
   */
  getNotificationBubblesUsingGet = (
    query?: {
      /** currencyCode */
      currencyCode?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfNotificationBubbles, void>({
      path: `/api/v2/notifications/bubbles`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name GetCommentsNotificationsUsingGet
   * @summary getCommentsNotifications
   * @request GET:/api/v2/notifications/comments
   */
  getCommentsNotificationsUsingGet = (
    query?: {
      exceptType?: string;
      isActionCompleted?: boolean;
      isRead?: boolean;
      needAction?: boolean;
      onlyType?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfNotificationDTO, void>({
      path: `/api/v2/notifications/comments`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name GetNoCommentsNotificationsUsingGet
   * @summary getNoCommentsNotifications
   * @request GET:/api/v2/notifications/nocomments
   */
  getNoCommentsNotificationsUsingGet = (
    query?: {
      exceptType?: string;
      isActionCompleted?: boolean;
      isRead?: boolean;
      needAction?: boolean;
      onlyType?: string;
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfNotificationDTO, void>({
      path: `/api/v2/notifications/nocomments`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name GetNotificationUsingGet
   * @summary getNotification
   * @request GET:/api/v2/notifications/{notificationId}
   */
  getNotificationUsingGet = (notificationId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfNotificationDTO, void>({
      path: `/api/v2/notifications/${notificationId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name SetBirthdateUsingPatch
   * @summary setBirthdate
   * @request PATCH:/api/v2/notifications/{notificationId}/birthdate
   */
  setBirthdateUsingPatch = (
    notificationId: number,
    query: {
      /**
       * birthDateTime
       * @format int64
       */
      birthDateTime: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfNotificationDTO, void>({
      path: `/api/v2/notifications/${notificationId}/birthdate`,
      method: "PATCH",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name CompleteNotificationUsingPatch
   * @summary completeNotification
   * @request PATCH:/api/v2/notifications/{notificationId}/complete
   */
  completeNotificationUsingPatch = (notificationId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfNotificationDTO, void>({
      path: `/api/v2/notifications/${notificationId}/complete`,
      method: "PATCH",
      ...params,
    });
  /**
   * No description
   *
   * @tags notification-controller-api-v-2
   * @name ReadNotificationUsingPatch
   * @summary readNotification
   * @request PATCH:/api/v2/notifications/{notificationId}/read
   */
  readNotificationUsingPatch = (notificationId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfNotificationDTO, void>({
      path: `/api/v2/notifications/${notificationId}/read`,
      method: "PATCH",
      ...params,
    });
}
