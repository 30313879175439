import revive_payload_client_4sVQNw7RlN from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/oskelly.ru/oskelly-site-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_eskviOYyLt from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/oskelly.ru/oskelly-site-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import custom_scrollbar_qjEzL88qwK from "/builds/oskelly.ru/oskelly-site-frontend/plugins/custom-scrollbar.ts";
import af_banner_client_j5HWnqyJiI from "/builds/oskelly.ru/oskelly-site-frontend/plugins/af-banner.client.ts";
import analytics_SVnwK7EMrr from "/builds/oskelly.ru/oskelly-site-frontend/plugins/analytics.ts";
import errors_5qvZOSbheL from "/builds/oskelly.ru/oskelly-site-frontend/plugins/errors.ts";
import maska_UHaKf2z1iQ from "/builds/oskelly.ru/oskelly-site-frontend/plugins/maska.ts";
import mindbox_client_01iWBm4suY from "/builds/oskelly.ru/oskelly-site-frontend/plugins/mindbox.client.ts";
import notification_client_B9MZmzG6b3 from "/builds/oskelly.ru/oskelly-site-frontend/plugins/notification.client.ts";
import sentry_client_shVUlIjFLk from "/builds/oskelly.ru/oskelly-site-frontend/plugins/sentry.client.ts";
import usedesk_client_ENqRwNoqDb from "/builds/oskelly.ru/oskelly-site-frontend/plugins/usedesk.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_t2GMTTFnMT,
  plugin_eskviOYyLt,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  custom_scrollbar_qjEzL88qwK,
  af_banner_client_j5HWnqyJiI,
  analytics_SVnwK7EMrr,
  errors_5qvZOSbheL,
  maska_UHaKf2z1iQ,
  mindbox_client_01iWBm4suY,
  notification_client_B9MZmzG6b3,
  sentry_client_shVUlIjFLk,
  usedesk_client_ENqRwNoqDb
]