import { RouteLocation } from 'vue-router';
import { useApi } from '~/restAPI';
import { Catalog } from '~/restAPI/Catalog';
import { Deeplink } from '~/restAPI/Deeplink';
import { Productpublication } from '~/restAPI/Productpublication';
import { Dictionary } from '~/restAPI/Dictionary';
import { mappedQueryParams } from '~/utils/api';
import { ISelectedFilters } from '~/types/filters';
import { isDefined, replaceEmptyArray } from '~/utils/extra';

type TCatalogQuery = NonNullable<Parameters<Catalog['getCatalogUsingGet']>[0]>;

function mapSelectedFiltersToAvailableFiltersQuery(filters: ISelectedFilters): TCatalogQuery {
  return {
    categoriesIds: replaceEmptyArray(filters.category),
    interestingConditions: replaceEmptyArray(filters.productCondition),
    interestingBrands: replaceEmptyArray(filters.brand),
    interestingSizes: replaceEmptyArray(filters.size),
    interestingAttributeValues: replaceEmptyArray(filters.filter),
    hasOurChoice: filters.hasOurChoice,
    isNewCollection: filters.newCollection,
    isAtOffice: filters.atOffice,
    isVintage: filters.vintage,
    isPro: isDefined(filters.isPro) ? filters.isPro : undefined,
    startPrice: filters.startPrice,
    endPrice: filters.endPrice,
  };
}

export function useCatalogProvider() {
  const { currentCountry } = useInternational();
  const { createApiInstance } = useApi();
  const deeplinkApi = createApiInstance(Deeplink);
  const catalogApi = createApiInstance(Catalog);
  const productPublicationApi = createApiInstance(Productpublication);
  const dictionaryApi = createApiInstance(Dictionary);

  function fetchPageInfo(route: RouteLocation) {
    return deeplinkApi.resolveLinkUsingPost({
      link: route.path,
    }, { format: 'json' })
      .then(({ data }) => data.data);
  }

  function fetchCatalog(query: TCatalogQuery) {
    return catalogApi.getCatalogUsingGet({
      ...query,
    }, { format: 'json' })
      .then(({ data }) => data.data);
  }

  function getCategoryTree() {
    return dictionaryApi.getCategoryTreeUsingGet11({ format: 'json' }).then((data) => data.data.data!.rootCategory!.children!);
  }

  function fetchAllBrands() {
    return dictionaryApi.getBrandsUsingGet11({ format: 'json' }).then((data) => data.data.data!);
  }

  function fetchBrandsByCategory(categoryId: number) {
    return catalogApi.getBrandsUsingGet1({
      categoriesIds: [categoryId],
      pageLength: 3000,
      currencyCode: currentCountry.value?.currency?.isoCode,
    }, { format: 'json' })
      .then(({ data }) => data.data);
  }

  function fetchAvailableFilters(filters: ISelectedFilters, params: {sellerId?: number, categoriesIds?: number[]}) {
    return catalogApi.getAvailableFiltersUsingGet({
      ...params,
      ...mapSelectedFiltersToAvailableFiltersQuery(filters),
    }, { format: 'json' })
      .then((data) => data.data.data);
  }

  function fetchAvailableFiltersWithRoute(route: RouteLocation, params: {sellerId?: number, categoriesIds?: number[]}) {
    return catalogApi.getAvailableFiltersUsingGet({
      ...params,
      ...mappedQueryParams(route),
    }, { format: 'json' })
      .then((data) => data.data.data);
  }

  function fetchSizes(categoryId: number) {
    return productPublicationApi.getSizesUsingGet1(categoryId, { format: 'json' }).then(({ data }) => data.data?.filter((s) => s.sizeType !== 'NO_SIZE'));
  }

  function fetchAttributes(categoryId: number) {
    return productPublicationApi.getAttributesUsingGet(categoryId, { format: 'json' }).then(({ data }) => data.data);
  }

  function fetchProductConditions() {
    return productPublicationApi.getProductConditionsUsingGet({ format: 'json' }).then(({ data }) => data.data ?? []);
  }

  function fetchLikedProducts() {
    return catalogApi.getLikedProductsUsingGet(
      { currencyCode: currentCountry.value?.currency?.isoCode },
      { format: 'json' },
    ).then(({ data }) => data.data);
  }

  return {
    fetchPageInfo,
    fetchCatalog,
    getCategoryTree,
    fetchBrandsByCategory,
    fetchAllBrands,
    fetchSizes,
    fetchAttributes,
    fetchProductConditions,
    fetchLikedProducts,
    fetchAvailableFilters,
    fetchAvailableFiltersWithRoute,
  };
}
