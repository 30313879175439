import { BeforeErrorHook, BeforeRequestHook } from 'ky/distribution/types/hooks';

import { CustomHTTPError } from '@/restAPI/error';

export const createHttpErrorHook: BeforeErrorHook = async (error) => {
  if (!error.response.ok) {
    const errorObj = await error.response.json();

    const response = {
      ...error.response,
      headers: error.response.headers,
      ok: error.response.ok,
      redirected: error.response.redirected,
      status: error.response.status,
      statusText: error.response.statusText,
      type: error.response.type,
      url: error.response.url,
      data: errorObj,
    };

    throw new CustomHTTPError(response, error.request, error.options);
  }

  throw error;
};

export const logRequestApiUrlHook: BeforeRequestHook = (req) => {
  if (!process.dev) {
    console.log(`requestApiUrl: ${req.url}`);
  }
};
