/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CommentDTO, ProductCommentBase64ImagesDataDTO } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Comments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags comments-controller
   * @name PublishCommentUsingPost
   * @summary publishComment
   * @request POST:/api/v2/comments
   */
  publishCommentUsingPost = (
    query?: {
      images?: File[];
      /** @format int64 */
      parentCommentId?: number;
      /** @format int64 */
      productId?: number;
      text?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<CommentDTO, void>({
      path: `/api/v2/comments`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags comments-controller
   * @name PublishCommentWithBase64ImagesUsingPost
   * @summary publishComment
   * @request POST:/api/v2/comments/base64-images
   */
  publishCommentWithBase64ImagesUsingPost = (data: ProductCommentBase64ImagesDataDTO, params: RequestParams = {}) =>
    this.request<CommentDTO, void>({
      path: `/api/v2/comments/base64-images`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags comments-controller
   * @name GetProductCommentsUsingGet
   * @summary getProductComments
   * @request GET:/api/v2/comments/product/{productId}
   */
  getProductCommentsUsingGet = (productId: number, params: RequestParams = {}) =>
    this.request<CommentDTO[], void>({
      path: `/api/v2/comments/product/${productId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags comments-controller
   * @name GetProductCommentsTreeUsingGet
   * @summary getProductCommentsTree
   * @request GET:/api/v2/comments/product/{productId}/tree
   */
  getProductCommentsTreeUsingGet = (productId: number, params: RequestParams = {}) =>
    this.request<CommentDTO[], void>({
      path: `/api/v2/comments/product/${productId}/tree`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags comments-controller
   * @name DeleteCommentUsingDelete
   * @summary deleteComment
   * @request DELETE:/api/v2/comments/{commentId}
   */
  deleteCommentUsingDelete = (commentId: number, params: RequestParams = {}) =>
    this.request<number, void>({
      path: `/api/v2/comments/${commentId}`,
      method: "DELETE",
      ...params,
    });
}
