import { useApi } from '~/restAPI';
import { Catalog } from '~/restAPI/Catalog';
import { Cart } from '~/restAPI/Cart';
import { Comments } from '~/restAPI/Comments';
import { ProductCommentBase64ImagesDataDTO } from '~/restAPI/data-contracts';
import { RequestParams } from '~/restAPI/http-client';

export function useProductService() {
  const { currentCountry } = useInternational();
  const { createApiInstance } = useApi();
  const catalogApi = createApiInstance(Catalog);
  const cartApi = createApiInstance(Cart);
  const commentsApi = createApiInstance(Comments);

  async function getProduct(productId: number, query?: {
    withSizeChart?: boolean;
  }) {
    return catalogApi
      .getProductUsingGet(
        productId,
        { ...query, currencyCode: currentCountry.value?.currency?.isoCode },
        { format: 'json' },
      ).then((data) => data!.data!.data!);
  }

  async function getProductLite(productId: number) {
    return catalogApi
      .getProductLiteUsingGet(productId, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  async function getSizes(categoryId: number) {
    return catalogApi
      .getSizesUsingGet(categoryId, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  async function likeProduct(productId: number) {
    return catalogApi
      .likeProductUsingPut(productId, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  async function dislikeProduct(productId: number) {
    return catalogApi
      .dislikeProductUsingPut(productId, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  async function followPrice(productId: number) {
    return catalogApi
      .followPriceUsingPut(productId, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  async function unfollowPrice(productId: number) {
    return catalogApi
      .unfollowPriceUsingPut(productId, { format: 'json' })
      .then((data) => data!.data!.data!);
  }

  async function addProductToCart(query: { productId: number; sizeId?: number; }) {
    return cartApi
      .addItemUsingPut(
        {
          ...query,
          currencyCode: currentCountry.value?.currency?.isoCode,
        },
        { format: 'json' },
      ).then((data) => data!.data!.data!);
  }

  async function removeProductFromCart(orderPositionId: number) {
    return cartApi
      .deletePositionUsingDelete(
        orderPositionId,
        { currencyCode: currentCountry.value?.currency?.isoCode },
        { format: 'json' },
      ).then((data) => data!.data!.data!);
  }

  async function getAllComments(productId: number) {
    return commentsApi
      .getProductCommentsUsingGet(productId, { format: 'json' })
      .then((data) => data!.data!);
  }

  async function getAllCommentsTree(productId: number, params: RequestParams = {}) {
    return commentsApi
      .getProductCommentsTreeUsingGet(productId, { ...params, format: 'json' })
      .then((data) => data.data);
  }

  async function postComment(params: ProductCommentBase64ImagesDataDTO) {
    return commentsApi
      .publishCommentWithBase64ImagesUsingPost(params, { format: 'json' })
      .then((data) => data?.data ?? []);
  }

  async function postCommentNoPhotos(params: { parentCommentId?: number; productId?: number; text?: string }) {
    return commentsApi
      .publishCommentUsingPost(params, { format: 'json' })
      .then((data) => data?.data ?? []);
  }

  async function deleteComment(commentId: number) {
    return commentsApi
      .deleteCommentUsingDelete(commentId, { format: 'json' })
      .then((data) => data!.data!);
  }

  return {
    catalogApi,
    cartApi,
    commentsApi,
    getProduct,
    getSizes,
    likeProduct,
    dislikeProduct,
    followPrice,
    unfollowPrice,
    addProductToCart,
    removeProductFromCart,
    getAllComments,
    getAllCommentsTree,
    postComment,
    postCommentNoPhotos,
    deleteComment,
    getProductLite,
  };
}
