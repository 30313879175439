import type { RouterConfig } from '@nuxt/schema';

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    const { scrollToTopFunc } = to.meta;
    const isScroll = typeof scrollToTopFunc === 'function' ? scrollToTopFunc(to, from) : scrollToTopFunc;

    if (isScroll !== undefined) {
      return isScroll && { top: 0 };
    }

    return { top: 0 };
  },
};
