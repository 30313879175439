import { AnalyticsEvents, AnyObject } from '~/types/analytics';

type OskellyAnalyticsEventFunction<T> = (data?: AnyObject) => T;

export interface OskellyAnalyticsEventMainData {
    os: string,
    language: string,
    version: string,
    device_type: string,
    platform: string,
    time: number,
    user_id: number,
    guest_token: string,
    previous_page?: string,
    page?: string,
    id_page?: string,
    category_id?: string | number,
    value?: any,
    experiment_ids?: string[],
    experiment_value_ids?: string[],
}

export type OskellyAnalyticsEventType = 'click' | 'view';

export enum OskellyAnalyticsActionType {
    AddToCart = 'add_to_cart',
    RemoveFromCart = 'remove_from_cart',
    Bargain = 'bargain',
    PriceSubscribe = 'price_subscribe',
    PriceUnsubscribe = 'price_unsubscribe',
    Wish = 'wish',
    UnWish = 'unwish',
    Like = 'wish',
    Unlike = 'unwish',
    Subscribe = 'subscribe',
    Unsubscribe = 'unsubscribe',
    CommentIcon = 'comment_icon',
    CommentBlock = 'comment_block',
    Edit = 'edit',
    DeleteProductCart = 'delete_product_cart',
    DeleteOrderCart = 'delete_order_cart',
    InfoExpertise = 'info_expertise',
    InfoDelivery = 'info_delivery',
    InfoSafeBottom = 'info_safe_bottom',
    InfoHelp = 'info_help',
    CartUnfinishedOrders = 'cart_unfinished_orders',
    CartOrders = 'cart_orders',
    ShowAll = 'show_all',
    Comment = 'comment',
    Promocode = 'promocode',
    Checkout = 'checkout',
    Card = 'card',
    AnotherCard = 'another_card',
    Back = 'back',
    Continue = 'continue',
    Pay = 'pay',
    PayOtherCard = 'pay_other_card',
    PayNoCards = 'no_cards',
    Main = 'main',
    Wishlist = 'wishlist',
    Login = 'login',
    SignIn = 'sign_in',
    Remember = 'remember',
    Accepted = 'accepted',
    EnterLogin = 'enter_login',
    Registration = 'registration',
    Product = 'product',
    Brand = 'brand',
    SizingChart = 'sizing_chart',
    SellerProfile = 'seller_profile',
    About = 'about',
    InfoCompany = 'info_company',
    Payment = 'payment',

    YandexSplitInfo = 'info_split',
    YandexSplitInfoClose = 'close',
    YandexSplitInfoBackToItem = 'back_to_item',

    ExclusiveSelectionDetailsClick = 'details',
}

export interface OskellyAnalyticsEvent extends OskellyAnalyticsEventMainData {
    event?: OskellyAnalyticsEventType,
    action?: OskellyAnalyticsActionType,
    productId?: number,
    product_id?: number,
    chapter?: string,
    chapter_id?: string,
    id_chapter?: string,
    item?: string,
    item_id?: string,
    id_item?: string | number | number[],
    index?: string,
}

type AnalyticsEvent<T extends keyof OskellyAnalyticsEvent> = OskellyAnalyticsEventFunction<RequiredFromType<OskellyAnalyticsEvent, T>>

export interface OskellyAnalyticsEvents {
    [AnalyticsEvents.Wishlist]: AnalyticsEvent<'event'>
    [AnalyticsEvents.Cart]: AnalyticsEvent<'event'>
    [AnalyticsEvents.ProductView]: AnalyticsEvent<'event' | 'product_id'>
    [AnalyticsEvents.CartAdd]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.CartRemove]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductBargain]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductFollow]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductUnfollow]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductFollowAuthorization]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductLike]: AnalyticsEvent<'event' | 'product_id' | 'action' | 'chapter'>
    [AnalyticsEvents.ProductDislike]: AnalyticsEvent<'event' | 'product_id' | 'action' | 'chapter'>
    [AnalyticsEvents.ProductWish]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductUnwish]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductLikeAuthorization]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductCommentAdd]: AnalyticsEvent<'event' | 'product_id' | 'action'>
    [AnalyticsEvents.ProductCommentAddAll]: AnalyticsEvent<'event' | 'product_id' | 'action'>

    [AnalyticsEvents.CartView]: AnalyticsEvent<'event' | 'chapter' | 'id_item'>
    [AnalyticsEvents.UserClick]: AnalyticsEvent<'event' | 'item' | 'id_item' | 'chapter'>
    [AnalyticsEvents.ProductClick]: AnalyticsEvent<'event' | 'item' | 'product_id' | 'chapter'>
    [AnalyticsEvents.ProductChangeClick]: AnalyticsEvent<'event' | 'action' | 'id_item' | 'product_id'>
    [AnalyticsEvents.ProductDeleteFromCart]: AnalyticsEvent<'event' | 'action' | 'product_id'>
    [AnalyticsEvents.OrderDeleteFromCart]: AnalyticsEvent<'event' | 'action' | 'id_item'>
    [AnalyticsEvents.CartCheckout]: AnalyticsEvent<'event' | 'item' | 'id_item'>
    [AnalyticsEvents.AboutServiceClick]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CartTabReady]: AnalyticsEvent<'event' | 'action'>
    // [AnalyticsEvents.CartTabUnfinished]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.ShowAllClick]: AnalyticsEvent<'event' | 'chapter'>
    [AnalyticsEvents.AddToCart]: AnalyticsEvent<'event' | 'action' | 'chapter'>
    [AnalyticsEvents.ProductsBlockView]: AnalyticsEvent<'event' | 'chapter'>
    [AnalyticsEvents.CartLoginView]: AnalyticsEvent<'event'>

    [AnalyticsEvents.CheckoutView]: AnalyticsEvent<'event' | 'id_item' | 'page'>
    [AnalyticsEvents.Comment]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.Promocode]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CheckoutPay]: AnalyticsEvent<'event' | 'id_item'>
    [AnalyticsEvents.CheckoutCardView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.CheckoutCardClick]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CheckoutGoPayment]: AnalyticsEvent<'event' | 'page' | 'action' | 'item'>
    [AnalyticsEvents.CheckoutGoPaymentOtherCard]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CheckoutGoPaymentNoCards]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CheckoutChangePaymentType]: AnalyticsEvent<'event' | 'action' | 'page' | 'value'>
    [AnalyticsEvents.CheckoutCompleteView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.CheckoutCompleteMain]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CheckoutCompleteWishlist]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CheckoutThanksProductClick]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.CheckoutYandexSplit]: AnalyticsEvent<'event' | 'page' | 'item_id' | 'item'>

    [AnalyticsEvents.DialogLoginTab]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.DialogSignUpTab]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.DialogLoginRemember]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.DialogLoginAccepted]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.EnterLogin]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.Registration]: AnalyticsEvent<'event' | 'action'>

    [AnalyticsEvents.BrandClick]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.ProductSizeTableOpen]: AnalyticsEvent<'event' | 'action'>

    [AnalyticsEvents.UserSubscribe]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.UserUnsubscribe]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.SellerClick]: AnalyticsEvent<'event' | 'action'>

    [AnalyticsEvents.ProductAboutClick]: AnalyticsEvent<'event' | 'action'>
    [AnalyticsEvents.ProductInfoCompanyClick]: AnalyticsEvent<'event' | 'action'>

    [AnalyticsEvents.SliderNext]: AnalyticsEvent<'event'>
    [AnalyticsEvents.SliderPrev]: AnalyticsEvent<'event'>

    [AnalyticsEvents.MenuLinkClick]: AnalyticsEvent<'event'>
    [AnalyticsEvents.CatalogMobileView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.CatalogMobileLinkClick]: AnalyticsEvent<'event'>
    [AnalyticsEvents.HeaderLinkClick]: AnalyticsEvent<'event'>
    [AnalyticsEvents.FiltersLinkClick]: AnalyticsEvent<'event'>
    [AnalyticsEvents.FiltersView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.PageView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.MainBlockView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.MainBlockClick]: AnalyticsEvent<'event'>

    [AnalyticsEvents.ClickEvent]: AnalyticsEvent<'event'>
    [AnalyticsEvents.BlockView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.SwiperBlockView]: AnalyticsEvent<'event'>
    [AnalyticsEvents.SwiperBlockClick]: AnalyticsEvent<'event'>

    [AnalyticsEvents.YandexSplitInfo]: AnalyticsEvent<'event' | 'id_item'>
    [AnalyticsEvents.YandexSplitInfoBackToItem]: AnalyticsEvent<'event' | 'page'>
    [AnalyticsEvents.YandexSplitInfoClose]: AnalyticsEvent<'event' | 'page'>

    [AnalyticsEvents.OCommunityBadgeClick]: AnalyticsEvent<'event' | 'page' | 'id_item' | 'id_chapter'>
    [AnalyticsEvents.OCommunityBadgeView]: AnalyticsEvent<'event' | 'page' | 'id_chapter'>
    [AnalyticsEvents.OCommunityDialogClose]: AnalyticsEvent<'event' | 'page'>
    [AnalyticsEvents.OCommunityDialogView]: AnalyticsEvent<'event' | 'page'>

    [AnalyticsEvents.ExclusiveSelectionDetailsClick]: AnalyticsEvent<'event' | 'page' | 'product_id'>

    [key: string]: any
}
