import { useApi } from '~/restAPI';
import { Products } from '~/restAPI/Products';
import { Deeplink } from '~/restAPI/Deeplink';
import { Home } from '~/restAPI/Home';
import {
  ProductFilterRequest,
  ProductFilterItemsRequest,
  ProductFilterInfoRequest,
} from '~/restAPI/data-contracts';

export function useCatalog() {
  const cancelGetFiltersToken = 'cancelGetFiltersToken';

  const { createApiInstance } = useApi();
  const productsApi = createApiInstance(Products);
  const deeplinkApi = createApiInstance(Deeplink);
  const homeApi = createApiInstance(Home);

  function getDeeplink(path: string, withSEO?: boolean) {
    return deeplinkApi.resolveLinkUsingPost({
      link: path,
      withSEO,
    }, { format: 'json' })
      .then(({ data }) => {
        const params = mappedParams(data.data?.catalogFilter);

        return {
          ...data.data,
          catalogFilter: params,
        };
      });
  }

  function getFilters(params: ProductFilterRequest = {}) {
    return productsApi.postApiV2ProductsFilter(params, { format: 'json', cancelToken: cancelGetFiltersToken })
      .then(({ data }) => data.data);
  }

  function cancelGetFilters() {
    productsApi.abortRequest(cancelGetFiltersToken);
  }

  function getProductsByFilters(params: ProductFilterItemsRequest, query?: { code?: string }) {
    return productsApi.postApiV2ProductsFilterItems(params, query)
      .then(({ data }) => data.data);
  }

  function getProductsFilterInfo(params: ProductFilterInfoRequest, query?: { code?: string }) {
    return productsApi.postApiV2ProductsFilterInfo(params, query)
      .then(({ data }) => data.data);
  }

  function getProductsForBanner(query: { id: string; countryId?: string; }, params: ProductFilterItemsRequest) {
    return homeApi.postApiV2HomeBannerFilterableItems(query, params)
      .then(({ data }) => data.data);
  }

  return {
    getDeeplink,
    getFilters,
    getProductsByFilters,
    getProductsFilterInfo,
    getProductsForBanner,
    cancelGetFilters,
  };
}
