import { ContentType, HttpClient, RequestParams } from '~/restAPI/http-client';
import { OskellyAnalyticsEvent } from '~/types/analytics/oskellyAnalytics';

export class Analytics<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags analytics-api
   * @name saveEvent
   * @summary saveEvent
   * @request POST: /events
   */
  public saveEvent = (
    event: OskellyAnalyticsEvent,
    params: RequestParams = {},
  ) =>
    this.request({
      path: '/events',
      method: 'POST',
      query: {},
      body: event,
      type: ContentType.Json,
      ...params,
    });
}
