import { AnalyticsEvents, AnyObject } from '~/types/analytics';

type GtmAnalyticsEventFunction<T> = (data?: AnyObject) => T & { eventCallback?: () => void };

export enum GtmAnalyticsActionType {
    Auth ='auth',
    Registration ='registration',
    SizeClick = 'size-click',
    EcomDetail = 'ecomDetail',
    EcomAdd = 'ecomAdd',
    EcomRemove = 'ecomRemove',
    FollowSuccess = 'follow-success',
    UnfollowSuccess = 'unfollow-success',
    FollowErrorAuthorization = 'follow-error-authorization',
    WishSuccess = 'wish-success',
    WishRemove = 'wish-remove',
    WishErrorAuthorization = 'wish-error-authorization',
    VhodClick = 'vhod-click',
    EcomCart = 'ecomCart',
    AddShippingInfo = 'add_shipping_info',
    AddPaymentInfo = 'add_payment_info',
    EcomPurchase = 'ecomPurchase',
    CouponApplySuccess = 'couponApplySuccess',
    CouponApplyFailed = 'couponApplyFailed',
    UserFollow = 'subscribe',
    UserUnfollow = 'unsubscribe',
    ViewItem = 'view_item',
    AddToCart = 'add_to_cart',
    RemoveFromCart = 'remove_from_cart',
    ViewCart = 'view_cart',
    BeginCheckout = 'begin_checkout',
    Purchase = 'purchase',
    OrderCreated = 'order_created',
    OpenBargain = 'open_bargain',
    ProductCommentSuccess = 'product_comment',
    SellButton = 'sell_button',
    ConciergeButton = 'concierge_button',
    ConciergeForm = 'concierge_form',
    ConciergeFormSubmit = 'concierge_form_submit',
}

export interface GtmAnalyticsEvent {
    event?: GtmAnalyticsActionType,
    ecommerce?: unknown,
    user_id?: string,
}

type AnalyticsEvent<T extends keyof GtmAnalyticsEvent> = GtmAnalyticsEventFunction<RequiredFromType<GtmAnalyticsEvent, T>>

export interface GtmAnalyticsEvents {
    [AnalyticsEvents.ProductSizeTableOpen]: AnalyticsEvent<'event'>,
    [AnalyticsEvents.ProductView]: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.CartAdd]: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.CartRemove]: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.ProductFollow]: AnalyticsEvent<'event'>,
    [AnalyticsEvents.ProductUnfollow]: AnalyticsEvent<'event'>,
    [AnalyticsEvents.ProductFollowAuthorization]: AnalyticsEvent<'event'>,
    [AnalyticsEvents.ProductLike]: AnalyticsEvent<'event'>,
    [AnalyticsEvents.ProductDislike]: AnalyticsEvent<'event'>,
    [AnalyticsEvents.ProductLikeAuthorization]: AnalyticsEvent<'event'>,
    [AnalyticsEvents.GoToLoginPage]: AnalyticsEvent<'event'>,

    [AnalyticsEvents.ProductDeleteFromCart]?: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.AddToCart]?: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.CartView]?: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.CheckoutView]?: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.CheckoutAddShippingInfo]?: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.CheckoutAddPaymentInfo]?: AnalyticsEvent<'event' | 'ecommerce'>,
    [AnalyticsEvents.CheckoutCompleteView]?: AnalyticsEvent<'event' | 'ecommerce'>,

    [AnalyticsEvents.PromocodeApplySuccess]?: AnalyticsEvent<'event'>,
    [AnalyticsEvents.PromocodeApplyFailed]?: AnalyticsEvent<'event'>,

    [AnalyticsEvents.UserFollow]?: AnalyticsEvent<'event' | 'user_id'>,
    [AnalyticsEvents.UserUnfollow]?: AnalyticsEvent<'event' | 'user_id'>,

    [key: string]: any
}
