/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Api2ResponseOfBoolean,
  Api2ResponseOfListOfUserDTO,
  Api2ResponseOfListOfUserDTO1,
  Api2ResponseOfPageOfUserDTO,
  Api2ResponseOfPublicProfileDTO,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Publicprofile<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name GetFollowersUsingGet
   * @summary getFollowers
   * @request GET:/api/v2/publicprofile/followers
   */
  getFollowersUsingGet = (
    query?: {
      /**
       * userId
       * @format int64
       */
      userId?: number;
      /** query */
      query?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfUserDTO, void>({
      path: `/api/v2/publicprofile/followers`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name GetFollowersUsingGet1
   * @summary getFollowers
   * @request GET:/api/v2/publicprofile/followers-page
   */
  getFollowersUsingGet1 = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
      /** query */
      query?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfUserDTO, void>({
      path: `/api/v2/publicprofile/followers-page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name ToggleFollowingUsingPost
   * @summary toggleFollowing
   * @request POST:/api/v2/publicprofile/following/toggle
   */
  toggleFollowingUsingPost = (
    query: {
      /**
       * userId
       * @format int64
       */
      userId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/publicprofile/following/toggle`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name FollowUsingPut
   * @summary follow
   * @request PUT:/api/v2/publicprofile/following/{userId}
   */
  followUsingPut = (userId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/publicprofile/following/${userId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name UnfollowUsingDelete
   * @summary unfollow
   * @request DELETE:/api/v2/publicprofile/following/{userId}
   */
  unfollowUsingDelete = (userId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/publicprofile/following/${userId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name GetFollowingsUsingGet
   * @summary getFollowings
   * @request GET:/api/v2/publicprofile/followings
   */
  getFollowingsUsingGet = (
    query?: {
      /**
       * userId
       * @format int64
       */
      userId?: number;
      /** query */
      query?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfUserDTO, void>({
      path: `/api/v2/publicprofile/followings`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name GetFollowingsUsingGet1
   * @summary getFollowings
   * @request GET:/api/v2/publicprofile/followings-page
   */
  getFollowingsUsingGet1 = (
    query?: {
      /** @format int32 */
      page?: number;
      /** @format int32 */
      pageSize?: number;
      /**
       * userId
       * @format int64
       */
      userId?: number;
      /** query */
      query?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfUserDTO, void>({
      path: `/api/v2/publicprofile/followings-page`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name FindUsersByNickNameMatchUsingGet
   * @summary Возвращает список пользователей по совпадению ников
   * @request GET:/api/v2/publicprofile/nick
   */
  findUsersByNickNameMatchUsingGet = (
    query: {
      /** Ник пользователя */
      nickName: string;
      /**
       * Кол-во найденных пользователей, которые надо вернуть
       * @format int32
       */
      count: number;
      /**
       * Только бутики
       * @default false
       */
      isPro?: boolean;
      /**
       * Нужен ли ответ с кол-вом товаров у пользователя
       * @default false
       */
      withProductCount?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfUserDTO, void>({
      path: `/api/v2/publicprofile/nick`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name GetProfileUsingGet
   * @summary getProfile
   * @request GET:/api/v2/publicprofile/{userId}
   */
  getProfileUsingGet = (
    userId: number,
    query?: {
      withCity?: boolean;
      withFollowers?: boolean;
      withFollowings?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPublicProfileDTO, void>({
      path: `/api/v2/publicprofile/${userId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags public-profile-controller-api-v-2
   * @name FindByUserIdsUsingGet
   * @summary Возвращает список пользователей по первичным ключам
   * @request GET:/api/v2/publicprofile
   */
  findByUserIdsUsingGet = (
    query: {
      /**
       * Первичные ключи пользователей
       * @format int64
       */
      userIds: any[];
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfUserDTO1, void>({
      path: `/api/v2/publicprofile`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
