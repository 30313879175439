import { useGTM } from '~/services/analytics/gtm';
import { useOskellyAnalytics } from '~/services/analytics/oskellyAnalytics';
import { AnalyticsEvents, AnalyticService } from '~/types/analytics';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const isEnabled = !process.dev;
  const { isLoading, isSyntheticUser } = useAccountStore();

  const { GTM_ID } = config.public;

  const services: AnalyticService[] = [
    useGTM(),
    useOskellyAnalytics(),
  ];

  useHead({
    script: isEnabled ? [
      {
        hid: 'gtm',
        children: `(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
            });
            window.dispatchEvent(new Event('gtmadded'));
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${GTM_ID}');`,
        type: 'text/javascript',
      },
      {
        type: 'text/javascript',
        children: `
          var _tmr = window._tmr || (window._tmr = []);
          _tmr.push({id: "3480605", type: "pageView", start: (new Date()).getTime()});
          (function (d, w, id) {
            if (d.getElementById(id)) return;
            var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
            ts.src = "https://top-fwz1.mail.ru/js/code.js";
            var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
            if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
          })(document, window, "tmr-code");`,
      },
    ] : [],
    noscript: isEnabled ? [
      {
        children: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        body: true,
      },
      {
        children: '<div><img src="https://top-fwz1.mail.ru/counter?id=3480605;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>',
        body: true,
      },
    ] : [],
  });

  function addEvent(name: AnalyticsEvents, data?: any) {
    if (!isEnabled || isSyntheticUser.value || process.server) {
      return Promise.resolve();
    }

    let promises:Promise<void>[] = [];

    try {
      promises = services.map((service) => service.addEvent(name, data));

      if (isLoading.value) {
        return new Promise<void>((resolve) => {
          const unwatch = watch(() => isLoading.value, () => {
            if (!isLoading.value) {
              resolve();
              nextTick(() => unwatch());
            }
          });
        }).then(() => Promise.all(promises));
      }
    } catch (e) {
      console.error(e);
    }

    return Promise.all(promises);
  }

  return {
    provide: {
      addEvent,
    },
  };
});
