import { defineRule, configure } from 'vee-validate';
import { email, required } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

let currentLocale = 'ru';

export function checkRequired(value: string | number | boolean | null | undefined) {
  if (!value && value !== 0) {
    return currentLocale === 'en' ? 'Fill in this field' : 'Заполните это поле';
  }
  return true;
}

function checkMinLength(value: string, [limit]: number[]) {
  if (!value || !value.length) {
    return true;
  }
  if (value.length < limit) {
    return `Минимум ${limit} знаков`;
  }
  return true;
}

function checkMaxLength(value: string, [limit]: number[]) {
  if (!value || !value.length) {
    return true;
  }
  if (value.length > limit) {
    return `Максимум ${limit} знаков`;
  }
  return true;
}

function checkMinMaxLength(value: string, [
  min,
  max,
]: number[
]) {
  if (!value || !value.length) {
    return true;
  }
  if (value.length < min || value.length > max) {
    return `От ${min} до ${max} знаков`;
  }
  return true;
}

function checkExactLength(value: string, [exact]: number[]) {
  if (!value || !value.length) {
    return true;
  }
  if (value.length === exact) {
    return `Строго ${exact} знаков`;
  }
  return true;
}

function checkMin(value: number, [limit]: number[]) {
  if (!value && value !== 0) {
    return true;
  }
  if (value < limit) {
    return `Минимум ${limit}`;
  }
  return true;
}

function checkMax(value: number, [limit]: number[]) {
  if (!value && value !== 0) {
    return true;
  }
  if (value > limit) {
    return `Максимум ${limit}`;
  }
  return true;
}

function checkPhone(value: string) {
  if (!value || !value.length) {
    return true;
  }
  if (value.length !== 18 && value.length !== 15) {
    return 'Укажите номер в формате +7 (999)-999-99-99';
  }
  return true;
}

function checkPhone2(value: string) {
  if (!value || !value.length) {
    return true;
  }
  if (value.length < 5) {
    return 'Укажите номер телефона';
  }
  if (value.length > 20) {
    return 'Максимум 20 символов';
  }
  return true;
}

function checkOskellyUserName(value: string) {
  if (!value || !value.length) {
    return true;
  }

  const re = /^[a-z0-9_]+$/;

  if (!re.test(value)) {
    return 'Имеются недопустимые символы. Допускаются только прописные (маленькие) латинские символы, цифры и знак подчеркивания';
  }

  return true;
}

function checkPassword(value: string) {
  if (!value || !value.length) {
    return true;
  }

  if (!/^[!@#$%^&*(),.?":{}|<>a-zA-Z0-9]{6,100}$/g.test(value)) {
    return 'Не может содержать в себе русские буквы и пробелы.';
  }

  return true;
}

function checkCity(value?: { country?: string }) {
  if (value?.country) {
    return true;
  }

  return 'Выберите населенный пункт из списка';
}

function checkCountry(value?: { name?: string }) {
  return !!value?.name;
}

defineRule('minLength', checkMinLength);
defineRule('maxLength', checkMaxLength);
defineRule('minMaxLength', checkMinMaxLength);
defineRule('exactLength', checkExactLength);
defineRule('min', checkMin);
defineRule('max', checkMax);
defineRule('phone', checkPhone);
defineRule('phone2', checkPhone2);

defineRule('required', required);
defineRule('email', email);
defineRule('oskellyUserName', checkOskellyUserName);
defineRule('password', checkPassword);

defineRule('city', checkCity);
defineRule('country', checkCountry);

configure({
  generateMessage: localize({
    ru: {
      messages: {
        required: 'Обязательное поле',
        email: 'Некорректный email',
      },
      fields: {
        promoCode: {
          required: 'Введите промокод',
        },
        checkoutAddress: {
          required: 'Выберите адрес',
        },
      },
    },
    en: {
      messages: {
        required: 'Required field',
        email: 'Incorrect email',
      },
      fields: {
        promoCode: {
          required: 'Enter promocode',
        },
        checkoutAddress: {
          required: 'Enter address',
        },
      },
    },
  }),
});

export function setLocale(locale: string) {
  localize(locale);
  currentLocale = locale;
}
