/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountDTOReq,
  AddressEndpointAggregationRequestDTO,
  AddressEndpointAggregationRequestUpdateDTO,
  AddressEndpointDTO,
  Api2ResponseOfAccountCredsDTO,
  Api2ResponseOfAccountDTO,
  Api2ResponseOfAddressEndpointAggregationListResult,
  Api2ResponseOfAddressEndpointAggregationResult,
  Api2ResponseOfAddressEndpointDTO,
  Api2ResponseOfBoolean,
  Api2ResponseOfBubblesDTO,
  Api2ResponseOfCounterpartyDTO,
  Api2ResponseOfIdAndJwtDTO,
  Api2ResponseOfIdDTO,
  Api2ResponseOfListOfAddressEndpointDTO,
  Api2ResponseOfListOflong,
  Api2ResponseOfListOfNotificationGroupDTO,
  Api2ResponseOflong,
  Api2ResponseOfMapOfstringAndstring,
  Api2ResponseOfPageOfUserfileDTO,
  Api2ResponseOfPreFilledRegisterFormAndOptions,
  Api2ResponseOfSimpleString,
  Api2ResponseOfString,
  Api2ResponseOfUserAuthInfoDTO,
  Api2ResponseOfUserfileDTO,
  Api2ResponseOfUserIdToAvatarPathMap,
  AppleAuthorizationRequest,
  AppleRegistrationRequest,
  CounterpartyDTO,
  GoogleAuthorizationRequest,
  GoogleRegistrationRequest,
  ImageUploadDTO,
  PhoneNumberAuthorizationRequest,
  PhoneNumberReAuthorizationRequest,
  RegisterFormRequest,
  SocialLoginRequest,
  SocialReAuthorizationRequest,
  SocialRegistrationRequest,
  YandexAuthorizationRequest,
  YandexRegistrationRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Account<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetAccountUsingGet
   * @summary getAccount
   * @request GET:/api/v2/account
   */
  getAccountUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfAccountDTO, void>({
      path: `/api/v2/account`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name UpdateAccountUsingPut
   * @summary updateAccount
   * @request PUT:/api/v2/account
   */
  updateAccountUsingPut = (data: AccountDTOReq, params: RequestParams = {}) =>
    this.request<Api2ResponseOfAccountDTO, void>({
      path: `/api/v2/account`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name V2AccountPayoutCounterpartiesList
   * @request GET:/api/v2/account/payoutCounterparties
   */
  v2AccountPayoutCounterpartiesList = (
    query: {
      /** @format int64 */
      orderId: number;
      onlyAvailable?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfCounterpartyDTO, any>({
      path: `/api/v2/account/payoutCounterparties`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SaveAddressEndpointUsingPut
   * @summary saveAddressEndpoint
   * @request PUT:/api/v2/account/addressendpoint
   */
  saveAddressEndpointUsingPut = (data: AddressEndpointDTO, params: RequestParams = {}) =>
    this.request<Api2ResponseOfAddressEndpointDTO, void>({
      path: `/api/v2/account/addressendpoint`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name DeleteAddressEndpointUsingDelete
   * @summary deleteAddressEndpoint
   * @request DELETE:/api/v2/account/addressendpoint
   */
  deleteAddressEndpointUsingDelete = (
    query: {
      /**
       * addressEndpointId
       * @format int64
       */
      addressEndpointId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAddressEndpointDTO, void>({
      path: `/api/v2/account/addressendpoint`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name V2AccountAddressendpointsList
   * @request GET:/api/v2/account/addressendpoints
   */
  v2AccountAddressendpointsList = (
    query?: {
      includedDeliveryCost?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfAddressEndpointDTO, any>({
      path: `/api/v2/account/addressendpoints`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name AuthUsingPost
   * @summary auth
   * @request POST:/api/v2/account/auth
   */
  authUsingPost = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfIdDTO, void>({
      path: `/api/v2/account/auth`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name ValidateRegisterFormUsingPost
   * @summary validateRegisterForm
   * @request POST:/api/v2/account/validateRegisterForm
   */
  validateRegisterFormUsingPost = (data: RegisterFormRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfSimpleString, void>({
      path: `/api/v2/account/validateRegisterForm`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name PhoneNumberRegisterUsingPost
   * @summary phoneNumberRegister
   * @request POST:/api/v2/account/phoneNumberRegister
   */
  phoneNumberRegisterUsingPost = (
    data: {
      authInfo?: PhoneNumberAuthorizationRequest;
      registerForm?: RegisterFormRequest;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/phoneNumberRegister`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name PhoneNumberAuthUsingPost
   * @summary phoneNumberAuth
   * @request POST:/api/v2/account/phoneNumberAuth
   */
  phoneNumberAuthUsingPost = (data: PhoneNumberAuthorizationRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/phoneNumberAuth`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name PhoneNumberReAuthUsingPost
   * @summary phoneNumberReAuth
   * @request POST:/api/v2/account/phoneNumberReAuth
   */
  phoneNumberReAuthUsingPost = (data: PhoneNumberReAuthorizationRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/phoneNumberReAuth`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name ParseSocialIdTokenToRegisterFormUsingGet
   * @summary parseSocialIdTokenToRegisterForm
   * @request GET:/api/v2/account/parseSocialIdTokenToRegisterForm
   */
  parseSocialIdTokenToRegisterFormUsingGet = (
    query: {
      socialAuthProvider: "APPLE" | "GOOGLE" | "YANDEX";
      socialIdToken: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPreFilledRegisterFormAndOptions, any>({
      path: `/api/v2/account/parseSocialIdTokenToRegisterForm`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GoogleRegisterUsingPost
   * @summary googleRegister
   * @request POST:/api/v2/account/googleRegister
   */
  googleRegisterUsingPost = (
    data: {
      authInfo?: GoogleRegistrationRequest;
      registerForm?: RegisterFormRequest;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/googleRegister`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GoogleAuthUsingPost
   * @summary googleAuth
   * @request POST:/api/v2/account/googleAuth
   */
  googleAuthUsingPost = (data: GoogleAuthorizationRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/googleAuth`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name YandexRegisterUsingPost
   * @summary yandexRegister
   * @request POST:/api/v2/account/yandexRegister
   */
  yandexRegisterUsingPost = (
    data: {
      authInfo?: YandexRegistrationRequest;
      registerForm?: RegisterFormRequest;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/yandexRegister`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name YandexAuthUsingPost
   * @summary yandexAuth
   * @request POST:/api/v2/account/yandexAuth
   */
  yandexAuthUsingPost = (data: YandexAuthorizationRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/yandexAuth`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name AppleRegisterUsingPost
   * @summary appleRegister
   * @request POST:/api/v2/account/appleRegister
   */
  appleRegisterUsingPost = (
    data: {
      authInfo?: AppleRegistrationRequest;
      registerForm?: RegisterFormRequest;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/appleRegister`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name AppleAuthUsingPost
   * @summary appleAuth
   * @request POST:/api/v2/account/appleAuth
   */
  appleAuthUsingPost = (data: AppleAuthorizationRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/appleAuth`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SocialReAuthUsingPost
   * @summary socialReAuth
   * @request POST:/api/v2/account/socialReAuth
   */
  socialReAuthUsingPost = (data: SocialReAuthorizationRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfIdAndJwtDTO, void>({
      path: `/api/v2/account/socialReAuth`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetAccountUsingGet1
   * @summary getAccount
   * @request GET:/api/v2/account/authInfo
   */
  getAccountUsingGet1 = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfUserAuthInfoDTO, void>({
      path: `/api/v2/account/authInfo`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name BindCardUsingPut
   * @summary bindCard
   * @request PUT:/api/v2/account/bindCard
   */
  bindCardUsingPut = (
    query?: {
      /** redirect */
      redirect?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfString, void>({
      path: `/api/v2/account/bindCard`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetBubblesUsingGet
   * @summary getBubbles
   * @request GET:/api/v2/account/bubbles
   */
  getBubblesUsingGet = (
    query?: {
      supportBoutique?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBubblesDTO, void>({
      path: `/api/v2/account/bubbles`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name CheckEmailIsFreeUsingPost
   * @summary checkEmailIsFree
   * @request POST:/api/v2/account/checkEmailIsFree
   */
  checkEmailIsFreeUsingPost = (
    query: {
      /** mail */
      mail: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/account/checkEmailIsFree`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name CheckNicknameIsFreeUsingPost
   * @summary checkNicknameIsFree
   * @request POST:/api/v2/account/checkNicknameIsFree
   */
  checkNicknameIsFreeUsingPost = (
    query: {
      /** nickname */
      nickname: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/account/checkNicknameIsFree`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SaveCounterpartyUsingPut
   * @summary saveCounterparty
   * @request PUT:/api/v2/account/counterparty
   */
  saveCounterpartyUsingPut = (data: CounterpartyDTO, params: RequestParams = {}) =>
    this.request<Api2ResponseOfCounterpartyDTO, void>({
      path: `/api/v2/account/counterparty`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SaveCounterpartyUsingDelete
   * @summary saveCounterparty
   * @request DELETE:/api/v2/account/counterparty
   */
  saveCounterpartyUsingDelete = (
    query: {
      /**
       * counterpartyId
       * @format int64
       */
      counterpartyId: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfCounterpartyDTO, void>({
      path: `/api/v2/account/counterparty`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetAccountCredsUsingGet
   * @summary getAccountCreds
   * @request GET:/api/v2/account/creds
   */
  getAccountCredsUsingGet = (
    query: {
      /** accountEmail */
      accountEmail: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAccountCredsDTO, void>({
      path: `/api/v2/account/creds`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name EmailSubscribeUsingPost
   * @summary emailSubscribe
   * @request POST:/api/v2/account/email-subscription
   */
  emailSubscribeUsingPost = (
    query: {
      /**
       * @min 0
       * @max 100
       * @minLength 0
       * @maxLength 100
       */
      emailAddress: string;
      /**
       * @min 0
       * @max 100
       * @minLength 0
       * @maxLength 100
       */
      name?: string;
      sex?: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfString, void>({
      path: `/api/v2/account/email-subscription`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetMyFollowingIdsUsingGet
   * @summary getMyFollowingIds
   * @request GET:/api/v2/account/followingIds
   */
  getMyFollowingIdsUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOflong, void>({
      path: `/api/v2/account/followingIds`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name UploadImageUsingPost
   * @summary uploadImage
   * @request POST:/api/v2/account/image
   */
  uploadImageUsingPost = (
    data: {
      /** @format binary */
      image: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfString, void>({
      path: `/api/v2/account/image`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SetPasswordUsingPut
   * @summary setPassword
   * @request PUT:/api/v2/account/password
   */
  setPasswordUsingPut = (
    query: {
      password: string;
      passwordOnceMore: string;
      resetToken: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/account/password`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SetPasswordUsingPatch
   * @summary setPassword
   * @request PATCH:/api/v2/account/password
   */
  setPasswordUsingPatch = (
    query: {
      newPassword: string;
      newPasswordOnceMore: string;
      oldPassword: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/account/password`,
      method: "PATCH",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetPushGroupsUsingGet
   * @summary getPushGroups
   * @request GET:/api/v2/account/pushGroups
   */
  getPushGroupsUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfNotificationGroupDTO, void>({
      path: `/api/v2/account/pushGroups`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SetPushGroupsUsingPut
   * @summary setPushGroups
   * @request PUT:/api/v2/account/pushGroups
   */
  setPushGroupsUsingPut = (
    query?: {
      /**
       * notificationGroupIds
       * @format int64
       */
      notificationGroupIds?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfNotificationGroupDTO, void>({
      path: `/api/v2/account/pushGroups`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetEmailGroupsUsingGet
   * @summary getEmailGroups
   * @request GET:/api/v2/account/emailGroups
   */
  getEmailGroupsUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfNotificationGroupDTO, void>({
      path: `/api/v2/account/emailGroups`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SetEmailGroupsUsingPut
   * @summary setEmailGroups
   * @request PUT:/api/v2/account/emailGroups
   */
  setEmailGroupsUsingPut = (
    query?: {
      /**
       * notificationGroupIds
       * @format int64
       */
      notificationGroupIds?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfNotificationGroupDTO, void>({
      path: `/api/v2/account/emailGroups`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetWhatsappGroupsUsingGet
   * @summary geWhatsappGroups
   * @request GET:/api/v2/account/whatsappGroups
   */
  getWhatsappGroupsUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfNotificationGroupDTO, void>({
      path: `/api/v2/account/whatsappGroups`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SetWhatsappGroupsUsingPut
   * @summary setWhatsappGroups
   * @request PUT:/api/v2/account/whatsappGroups
   */
  setWhatsappGroupsUsingPut = (
    query?: {
      /**
       * notificationGroupIds
       * @format int64
       */
      notificationGroupIds?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfNotificationGroupDTO, void>({
      path: `/api/v2/account/whatsappGroups`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name RawauthUsingPost
   * @summary rawauth
   * @request POST:/api/v2/account/rawauth
   */
  rawauthUsingPost = (
    query?: {
      /** rawEmail */
      rawEmail?: string;
      /** rawPassword */
      rawPassword?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfIdDTO, void>({
      path: `/api/v2/account/rawauth`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name RegisterUsingPost
   * @summary register
   * @request POST:/api/v2/account/register
   */
  registerUsingPost = (
    query: {
      registerConfirmPassword: string;
      registerEmail: string;
      registerNickname: string;
      /**
       * @min 3
       * @max 2147483647
       * @minLength 3
       * @maxLength 2147483647
       */
      registerPassword: string;
      registerPhone?: string;
      subscriptionApprove?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOflong, void>({
      path: `/api/v2/account/register`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name ResetPasswordUsingPost
   * @summary resetPassword
   * @request POST:/api/v2/account/reset
   */
  resetPasswordUsingPost = (
    query: {
      email: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/account/reset`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetSexUsingGet
   * @summary getSex
   * @request GET:/api/v2/account/sex
   */
  getSexUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfString, void>({
      path: `/api/v2/account/sex`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SetSexUsingPut
   * @summary setSex
   * @request PUT:/api/v2/account/sex
   */
  setSexUsingPut = (
    query: {
      /** sex */
      sex: "ADULT" | "BOY" | "CHILD" | "FEMALE" | "GIRL" | "MALE";
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfString, void>({
      path: `/api/v2/account/sex`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SocialAuthUsingPost
   * @summary socialAuth
   * @request POST:/api/v2/account/social-login
   */
  socialAuthUsingPost = (data: SocialLoginRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfMapOfstringAndstring, void>({
      path: `/api/v2/account/social-login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name SocialRegisterUsingPost
   * @summary socialRegister
   * @request POST:/api/v2/account/social-register
   */
  socialRegisterUsingPost = (data: SocialRegistrationRequest, params: RequestParams = {}) =>
    this.request<Api2ResponseOfMapOfstringAndstring, void>({
      path: `/api/v2/account/social-register`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetUserfilesPageUsingGet
   * @summary getUserfilesPage
   * @request GET:/api/v2/account/userfiles
   */
  getUserfilesPageUsingGet = (
    query?: {
      /**
       * page
       * @format int32
       */
      page?: number;
      /**
       * pageSize
       * @format int32
       */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfPageOfUserfileDTO, void>({
      path: `/api/v2/account/userfiles`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name UploadUserfileUsingPost
   * @summary uploadUserfile
   * @request POST:/api/v2/account/userfiles
   */
  uploadUserfileUsingPost = (
    data: ImageUploadDTO,
    query?: {
      /** title */
      title?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfUserfileDTO, void>({
      path: `/api/v2/account/userfiles`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetUserfileUsingGet
   * @summary getUserfile
   * @request GET:/api/v2/account/userfiles/{userfileId}
   */
  getUserfileUsingGet = (userfileId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfUserfileDTO, void>({
      path: `/api/v2/account/userfiles/${userfileId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags account-controller-api-v-2
   * @name GetUserIdToAvatarPathMapUsingGet
   * @summary getUserIdToAvatarPathMap
   * @request GET:/api/v2/account/user-field/avatar-path
   */
  getUserIdToAvatarPathMapUsingGet = (
    query: {
      /** userIdList */
      userIds: number[];
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfUserIdToAvatarPathMap, void>({
      path: `/api/v2/account/user-field/avatar-path`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-address-controller-api-v-2
   * @name AccountAddressCreateUsingPost
   * @summary accountAddresCreate
   * @request POST:/api/v2/account/addresses
   */
  accountAddressCreateUsingPost = (data: AddressEndpointAggregationRequestDTO, params: RequestParams = {}) =>
    this.request<Api2ResponseOfAddressEndpointAggregationResult, void>({
      path: `/api/v2/account/addresses`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-address-controller-api-v-2
   * @name AccountAddressUpdateUsingPut
   * @summary accountAddresUpdate
   * @request PUT:/api/v2/account/addresses
   */
  accountAddressUpdateUsingPut = (data: AddressEndpointAggregationRequestUpdateDTO, params: RequestParams = {}) =>
    this.request<Api2ResponseOfAddressEndpointAggregationResult, void>({
      path: `/api/v2/account/addresses`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-address-controller-api-v-2
   * @name AccountAddresGetAllByUser
   * @summary accountAddresGetAllByUser
   * @request GET:/api/v2/account/addresses
   */
  accountAddresGetAllByUser = (
    query?: {
      context?: "APPLICATION" | "SELLER_ADDRESS" | "BUYER_ADDRESS" | "ALL_COUNTRIES" | "PHONE";
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfAddressEndpointAggregationListResult, void>({
      path: `/api/v2/account/addresses`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-address-controller-api-v-2
   * @name AccountAddressDeleteUsingDelete
   * @summary accountAddresDelete
   * @request DELETE:/api/v2/account/addresses/{aggregationId}
   */
  accountAddressDeleteUsingDelete = (aggregationId: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/v2/account/addresses/${aggregationId}`,
      method: "DELETE",
      ...params,
    });
}
