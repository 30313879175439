import { AnalyticsEvents, AnyObject } from '~/types/analytics';
import { OrderPositionDTORes, ProductDTO, ProductDTORes } from '~/restAPI/data-contracts';
import { GtmAnalyticsActionType, GtmAnalyticsEvents } from '~/types/analytics/gtmAnalytics';
import { isDefined } from '~/utils/extra';

function compileProductToEvent(product: ProductDTO | ProductDTORes | OrderPositionDTORes) {
  return {
    item_name: `${product.category?.singularName ?? product.categoryName} ${product.brand?.name ?? product.brandName}`,
    item_id: product.productId,
    item_brand: product.brand?.name ?? product.brandName,
    item_category: product.category?.singularName ?? product.categoryName,
    price: product.price ?? product.amount,
    quantity: product.count ?? 1,
  };
}

function compileProductsToEvent(products: OrderPositionDTORes[] = []) {
  return products.map((p) => compileProductToEvent(p));
}

const gtmEventsMap: GtmAnalyticsEvents = {
  [AnalyticsEvents.EnterLogin]: () => ({ event: GtmAnalyticsActionType.Auth }),
  [AnalyticsEvents.Registration]: () => ({ event: GtmAnalyticsActionType.Registration }),

  [AnalyticsEvents.ProductBargain]: () => ({ event: GtmAnalyticsActionType.OpenBargain }),
  [AnalyticsEvents.ProductCommentSuccess]: () => ({ event: GtmAnalyticsActionType.ProductCommentSuccess }),

  [AnalyticsEvents.HeaderLinkClick]: (data = {}) => {
    if (data.item === 'sell') {
      return {
        event: GtmAnalyticsActionType.SellButton,
      };
    }

    if (data.item === 'concierge') {
      return {
        event: GtmAnalyticsActionType.ConciergeButton,
      };
    }

    return null;
  },

  [AnalyticsEvents.ClickEvent]: (data = {}) => {
    if (data.item === 'details' && data.action === 'shop') {
      return {
        event: GtmAnalyticsActionType.ConciergeForm,
      };
    }

    return null;
  },

  [AnalyticsEvents.ConciergeFormSubmit]: () => ({ event: GtmAnalyticsActionType.ConciergeFormSubmit }),

  [AnalyticsEvents.ProductSizeTableOpen]: () => ({ event: GtmAnalyticsActionType.SizeClick }),
  [AnalyticsEvents.ProductView]: (data = {}) => ({
    event: GtmAnalyticsActionType.ViewItem,
    ecommerce: { items: [compileProductToEvent(data)] },
  }),
  [AnalyticsEvents.CartAdd]: (data = {}) => ({
    event: GtmAnalyticsActionType.AddToCart,
    ecommerce: { items: [compileProductToEvent(data)] },
  }),
  [AnalyticsEvents.CartRemove]: (data = {}) => ({
    event: GtmAnalyticsActionType.RemoveFromCart,
    ecommerce: { items: [compileProductToEvent(data)] },
  }),
  [AnalyticsEvents.ProductFollow]: (data) => ({ event: GtmAnalyticsActionType.FollowSuccess }),
  [AnalyticsEvents.ProductUnfollow]: (data) => ({ event: GtmAnalyticsActionType.UnfollowSuccess }),
  [AnalyticsEvents.ProductFollowAuthorization]: (data) => ({ event: GtmAnalyticsActionType.FollowErrorAuthorization }),
  [AnalyticsEvents.ProductLike]: (data) => ({ event: GtmAnalyticsActionType.WishSuccess }),
  [AnalyticsEvents.ProductDislike]: (data) => ({ event: GtmAnalyticsActionType.WishRemove }),
  [AnalyticsEvents.ProductLikeAuthorization]: (data) => ({ event: GtmAnalyticsActionType.WishErrorAuthorization }),
  [AnalyticsEvents.GoToLoginPage]: () => ({ event: GtmAnalyticsActionType.VhodClick }),

  [AnalyticsEvents.ProductDeleteFromCart]: (data = {}) => ({
    event: GtmAnalyticsActionType.RemoveFromCart,
    ecommerce: { items: [compileProductToEvent(data.product)] },
  }),
  [AnalyticsEvents.OrderDeleteFromCart]: (data = {}) => ({
    event: GtmAnalyticsActionType.RemoveFromCart,
    ecommerce: { items: [compileProductToEvent(data.products)] },
  }),
  [AnalyticsEvents.AddToCart]: (data = {}) => ({
    event: GtmAnalyticsActionType.AddToCart,
    ecommerce: { items: [compileProductToEvent(data.product)] },
  }),

  [AnalyticsEvents.CartView]: (data = {}) => ({
    event: GtmAnalyticsActionType.ViewCart,
    ecommerce: { items: compileProductsToEvent(data.products) },
  }),
  [AnalyticsEvents.CheckoutView]: (data = {}) => ({
    event: GtmAnalyticsActionType.BeginCheckout,
    ecommerce: { items: compileProductsToEvent(data.products) },
  }),
  [AnalyticsEvents.CheckoutAddShippingInfo]: (data = {}) => ({
    event: GtmAnalyticsActionType.AddShippingInfo,
    ecommerce: { items: compileProductsToEvent(data.products) },
  }),
  [AnalyticsEvents.CheckoutAddPaymentInfo]: (data = {}) => ({
    event: GtmAnalyticsActionType.AddPaymentInfo,
    ecommerce: { items: compileProductsToEvent(data.products) },
  }),
  [AnalyticsEvents.CheckoutCompleteView]: (data = {}) => ({
    event: GtmAnalyticsActionType.Purchase,
    ecommerce: {
      transaction_id: data?.order?.id,
      value: data?.order?.discount?.resultAmountWithDeliveryCost ?? data?.order?.finalAmount,
      currency: 'RUB',
      shipping: data?.order?.deliveryCost,
      coupon: data?.order?.discount?.code,
      items: compileProductsToEvent(data?.order?.items),
    },
    ...(isDefined(data.isNewUser) ? {
      clientType: data.isNewUser ? 'new' : 'returning',
    } : {}),
  }),
  [AnalyticsEvents.CheckoutOrderCreated]: (data: any = {}) => ({
    event: GtmAnalyticsActionType.OrderCreated,
    transaction_id: data.orderId,
    value: data.totalComp,
    clientType: data?.isNewUser ? 'new' : 'returning',
    userId: data.sellerId,
    payment_type: data.paymentType,
  }),
  [AnalyticsEvents.PromocodeApplySuccess]: (data) => ({
    event: GtmAnalyticsActionType.CouponApplySuccess,
    couponCode: data?.value,
  }),
  [AnalyticsEvents.PromocodeApplyFailed]: (data) => ({
    event: GtmAnalyticsActionType.CouponApplyFailed,
    couponCode: data?.value,
  }),
  [AnalyticsEvents.UserFollow]: (data) => ({
    event: GtmAnalyticsActionType.UserFollow,
    user_id: data?.userId?.toString() as string,
  }),
  [AnalyticsEvents.UserUnfollow]: (data) => ({
    event: GtmAnalyticsActionType.UserUnfollow,
    user_id: data?.userId?.toString() as string,
  }),
};

export function getGTMEvent(name: AnalyticsEvents, data?: AnyObject) {
  const currEvent = gtmEventsMap[name];

  return currEvent ? currEvent(data) : null;
}

export function useGTM() {
  const account = useAccountStore();

  function addToDataLayer(data: unknown) {
    window.dataLayer = window.dataLayer ?? [];

    if (isDefined(data) && typeof data === 'object') {
      // eslint-disable-next-line no-param-reassign
      data.userId = account?.account?.value?.id;
    }

    window.dataLayer.push(data);
  }

  function addEvent(name: AnalyticsEvents, data?: AnyObject) {
    return new Promise<void>((resolve) => {
      const gtmEvent = getGTMEvent(name, data);

      if (!gtmEvent) {
        resolve();
        return;
      }

      gtmEvent.eventCallback = () => {
        resolve();
      };

      addToDataLayer({ ecommerce: null });
      addToDataLayer(gtmEvent);
    });
  }

  return {
    addEvent,
  };
}
