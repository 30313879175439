import { AFBannersAdditionalParams, IAFBannersOptions } from '~/types/afBanner';

export default defineNuxtPlugin(() => {
  const { isMobile } = useUiBreakpoints();
  const isEnabled = isMobile.value;
  const config = useRuntimeConfig();
  const key = config.public.AF_BANNERS_KEY;
  const { afterEach, beforeEach } = useRouter();
  const counter = useState('afBannerRouteCounter', () => 0);
  const afBannerIsOpen = useState('afBannerIsOpen', () => false);
  let observer: MutationObserver | null = null;
  let timeoutId = 0;
  const bannerContainerId = 'banner-container';
  let hasBannerInDOM = false;

  useHead({
    script: isEnabled ? [
      {
        children: `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",
        {banners: {key: "${key}"}});`,
      },
    ] : [],
  });

  function createBannerContainer() {
    if (document.querySelector('#banner-container')) {
      return;
    }

    const bannerContainer = document.createElement('div');

    bannerContainer.id = bannerContainerId;

    document.body.appendChild(bannerContainer);
  }

  function afBannerShow(options?: IAFBannersOptions) {
    if (checkEnable() && isDefined(window.AF)) {
      afBannerIsOpen.value = true;

      createBannerContainer();

      window.AF('banners', 'showBanner', options);

      observer = new MutationObserver((mutations) => {
        const isRemove = isDefined(mutations.find(({ removedNodes }) => removedNodes.length > 0));
        const isAdd = isDefined(mutations.find(({ addedNodes }) => addedNodes.length > 0));

        if (isRemove) {
          afBannerIsOpen.value = false;
          destroy();
        }

        if (isAdd) {
          hasBannerInDOM = true;
        }
      });

      observer.observe(document.getElementById(bannerContainerId)!, {
        childList: true,
      });

      timeoutId = window.setTimeout(() => {
        if (hasBannerInDOM) {
          return;
        }

        hasBannerInDOM = false;
        afBannerIsOpen.value = false;
        observer?.disconnect();
        observer = null;
      }, 30000);
    }
  }

  function destroy() {
    clearTimeout(timeoutId);
    observer?.disconnect();
    observer = null;
  }

  function afBannerHide() {
    if (checkEnable() && isDefined(window.AF)) {
      window.AF('banners', 'hideBanner');

      afBannerIsOpen.value = false;

      clearTimeout(timeoutId);
    }
  }

  function afBannerUpdateParams(options?: AFBannersAdditionalParams) {
    if (checkEnable() && isDefined(window.AF)) {
      window.AF('banners', 'updateParams', options);
    }
  }

  function checkEnable() {
    if (isEnabled) {
      return true;
    }

    console.log('Widget AF banners is disabled');

    return false;
  }

  if (checkEnable()) {
    afterEach(() => {
      counter.value++;

      if (counter.value === 3) {
        afBannerShow({ bannerContainerQuery: `#${bannerContainerId}` });
      }
    });

    beforeEach((to, from, next) => {
      afBannerIsOpen.value = false;
      destroy();
      next();
    });
  }

  return {
    provide: {
      afBannerIsOpen,
    },
  };
});
