import { ProductDTORes } from '~/restAPI/data-contracts';
import { useProductService } from '~/services/product';
import { useCatalogProvider } from '~/providers/catalog';
import { AnalyticsEvents, IOptions } from '~/types/analytics';

export function useWishlistStore() {
  const count = useState('wishlistStoreCount', () => 0);
  const products = useState<undefined | ProductDTORes[]>('wishlistStoreProducts', () => undefined);
  let chapter = '';
  let idChapter = '';
  let analyticsOpts: IOptions | undefined;

  async function toggleLike(productId: number, isLiked: boolean, chapterBlock?: string, chapterId?: string, analytics?: IOptions) {
    chapter = chapterBlock ?? '';
    idChapter = chapterId ?? '';
    analyticsOpts = analytics;

    if (isLiked) {
      return removeLike(productId);
    }
    return addLike(productId);
  }

  // eslint-disable-next-line consistent-return
  async function addLike(productId: number) {
    const { likeProduct } = useProductService();

    try {
      addAnalyticsEvent(true, true, productId);

      const data = await likeProduct(productId);

      if (data) {
        increaseCount();
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line consistent-return
  async function removeLike(productId: number) {
    const { dislikeProduct } = useProductService();

    try {
      addAnalyticsEvent(false, true, productId);

      const data = await dislikeProduct(productId);

      if (data) {
        decreaseCount();
      }

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getWishListProducts() {
    const { fetchLikedProducts } = useCatalogProvider();

    try {
      products.value = await fetchLikedProducts();
      count.value = products.value?.length ?? 0;
    } catch (error) {
      console.error(error);
    }
  }

  function increaseCount() {
    count.value++;
  }

  function decreaseCount() {
    count.value--;
  }

  function addAnalyticsEvent(state: boolean, isSuccess: boolean, productId: number) {
    const { $addEvent } = useNuxtApp();
    const evName = state ? AnalyticsEvents.ProductLike : AnalyticsEvents.ProductDislike;

    const options: any = {
      item: analyticsOpts?.item || 'product',
      id_item: analyticsOpts?.searchId,
      product_id: productId,
      chapter: analyticsOpts?.chapter || chapter || undefined,
      id_chapter: analyticsOpts?.id_chapter || idChapter || undefined,
      chapter_index: analyticsOpts?.chapter_index,
      segment_id: analyticsOpts?.segment_id,
      index: analyticsOpts?.index,
      page: analyticsOpts?.page,
      action: analyticsOpts?.action,
      value: analyticsOpts?.value,
    };

    if (analyticsOpts?.searchId) {
      options.page = 'search';
    }

    $addEvent(evName, options);

    chapter = '';
  }

  return {
    count,
    products,
    toggleLike,
    addLike,
    removeLike,
    getWishListProducts,
    increaseCount,
    decreaseCount,
    addAnalyticsEvent,
  };
}
