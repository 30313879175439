
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/ru'
import 'dayjs/locale/en'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "ru"


dayjs.updateLocale("ru")
dayjs.locale('ru')


export default dayjs
