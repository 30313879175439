export function useGlobalSettings() {
  const { getCountries } = useInternational();
  const { getAccount, account, isAuth } = useAccountStore();
  const { getCart } = useCartStore();
  const { count: wishlistCount } = useWishlistStore();
  const { getNotificationsCount } = useNotificationsStore();
  const { isAuthDialogOpen } = useAccountStore();

  async function getGlobalSettings() {
    await getCountries();
    await getAccount();

    if (isAuthDialogOpen.value) return;

    await getCart();

    if (!isAuth.value) {
      // TODO вернуть когда бэк будет возвращать кол-во товаров в корзине
      // await getCart();
      return;
    }

    wishlistCount.value = account.value?.productLikesCount ?? 0;

    await getNotificationsCount();
  }

  return {
    getGlobalSettings,
  };
}
