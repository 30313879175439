import { setLocale } from '~/utils/validationRules';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isInternational } = useInternational();

  setLocale(isInternational ? 'en' : 'ru');

  if (to.fullPath.includes('conciergeLink')) {
    const { getDeeplink } = useCatalog();
    const { activeMenuCategory } = useActiveMenuCategory();

    try {
      const data = await getDeeplink(to.path);

      if (data.object) {
        return navigateTo(`/homeBanner/${data.object}`);
      }

      return navigateTo(`/${activeMenuCategory.value}`);
    } catch (e) {
      return navigateTo(`/${activeMenuCategory.value}`);
    }
  }

  return undefined;
});
