import UAParser from 'ua-parser-js';
import type { RouteRecordName } from 'vue-router';

import { useAnalyticsProvider } from '~/providers/analytics';
import {
  OskellyAnalyticsActionType,
  OskellyAnalyticsEventMainData,
  OskellyAnalyticsEvents,
} from '~/types/analytics/oskellyAnalytics';
import { AnalyticsEvents, AnyObject } from '~/types/analytics';
import { ECartInfo } from '~/types/cart';
import { OrderDTO } from '~/restAPI/data-contracts';

const ua = process.client ? new UAParser().getResult() : null;

export function getCommunity(data?: AnyObject | null) {
  return data?.exclusiveSelectionTime ? 'community' : undefined;
}

const oskellyEventsMap: OskellyAnalyticsEvents = {
  [AnalyticsEvents.Wishlist]: () => ({
    event: 'click',
  }),
  [AnalyticsEvents.Cart]: () => ({
    event: 'click',
  }),
  [AnalyticsEvents.ProductView]: (data) => ({
    event: 'view',
    product_id: data!.productId as number,
    value: getCommunity(data),
    previous_page: (() => {
      let param = compilePageParam(data!.previousPage as RouteRecordName);

      if (!param && document.referrer) {
        param = document.referrer;
      }
      return param;
    })(),
  }),
  [AnalyticsEvents.BrandClick]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Brand,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),
  [AnalyticsEvents.ProductSizeTableOpen]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.SizingChart,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),
  [AnalyticsEvents.CartAdd]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.AddToCart,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),
  [AnalyticsEvents.CartRemove]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.RemoveFromCart,
    product_id: data!.productId as number,
  }),
  [AnalyticsEvents.ProductBargain]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Bargain,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),
  [AnalyticsEvents.ProductFollow]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.PriceSubscribe,
    product_id: data!.productId as number,
  }),
  [AnalyticsEvents.ProductUnfollow]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.PriceUnsubscribe,
    product_id: data!.productId as number,
  }),
  [AnalyticsEvents.ProductFollowAuthorization]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.PriceSubscribe,
    product_id: data!.productId as number,
  }),
  [AnalyticsEvents.ProductLike]: (data) => {
    const result: any = {
      event: 'click',
      action: OskellyAnalyticsActionType.Like,
      product_id: data?.product_id as number,
      item: data?.item,
      id_item: data?.id_item,
      chapter: data?.chapter as string,
      id_chapter: data?.id_chapter as string,
      chapter_index: data?.chapter_index as number,
      segment_id: data?.segment_id as number,
      value: data?.value,
      index: data?.index as number,
    };

    if (data?.page) {
      result.page = data.page;
    }

    return result;
  },
  [AnalyticsEvents.ProductDislike]: (data) => {
    const result: any = {
      event: 'click',
      action: OskellyAnalyticsActionType.Unlike,
      product_id: data?.product_id as number,
      item: data?.item,
      id_item: data?.id_item,
      chapter: data?.chapter as string,
      id_chapter: data?.id_chapter as string,
      chapter_index: data?.chapter_index as number,
      segment_id: data?.segment_id as number,
      value: data?.value,
      index: data?.index as number,
    };

    if (data?.page) {
      result.page = data.page;
    }

    return result;
  },
  [AnalyticsEvents.ProductLikeAuthorization]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Wish,
    product_id: data!.productId as number,
  }),
  [AnalyticsEvents.ProductWish]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Wish,
    product_id: data!.productId as number,
  }),
  [AnalyticsEvents.ProductUnwish]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.UnWish,
    product_id: data!.productId as number,
  }),
  [AnalyticsEvents.UserSubscribe]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Subscribe,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),
  [AnalyticsEvents.UserUnsubscribe]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Unsubscribe,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),
  [AnalyticsEvents.SellerClick]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.SellerProfile,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),
  [AnalyticsEvents.ProductCommentAdd]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.CommentBlock,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),

  [AnalyticsEvents.ProductCommentAddAll]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.CommentIcon,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),

  [AnalyticsEvents.ProductAboutClick]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.About,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),

  [AnalyticsEvents.ProductInfoCompanyClick]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.InfoCompany,
    product_id: data!.productId as number,
    value: getCommunity(data),
  }),

  [AnalyticsEvents.CartView]: (data) => ({
    event: 'view',
    chapter: data?.chapter as string,
    id_item: data?.idItem as number[],
    previous_page: data?.previousPage as string,
  }),
  [AnalyticsEvents.UserClick]: (data) => ({
    event: 'click',
    item: data?.item as string || 'profile',
    id_item: String(data?.seller_id),
    chapter: data?.chapter as string,
    id_chapter: data?.id_chapter as string,
    chapter_index: data?.chapter_index as number,
    segment_id: data?.segment_id as number,
    action: data?.action as string,
    value: data?.value,
  }),
  [AnalyticsEvents.ProductClick]: (data) => {
    const result: any = {
      event: 'click',
      item: 'product',
      product_id: data?.product_id as number,
      id_item: data?.id_item?.toString(),
      chapter: data?.chapter as string,
      id_chapter: data?.id_chapter as string,
      index: data?.index,
      value: data?.value,
    };

    if (data?.page) {
      result.page = data.page;
    }

    return result;
  },
  [AnalyticsEvents.ProductChangeClick]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Edit,
    id_item: String(data?.seller_id),
    product_id: data?.product_id as number,
  }),
  [AnalyticsEvents.ProductDeleteFromCart]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.DeleteProductCart,
    product_id: data?.product?.productId as number,
  }),
  [AnalyticsEvents.OrderDeleteFromCart]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.DeleteOrderCart,
    id_item: String(data?.order_id),
  }),
  [AnalyticsEvents.CartCheckout]: (data) => ({
    event: 'click',
    item: 'checkout',
    id_item: String(data?.seller_id),
  }),
  [AnalyticsEvents.CartTabReady]: () => ({
    event: 'click',
    action: OskellyAnalyticsActionType.CartOrders,
  }),
  [AnalyticsEvents.CartTabUnfinished]: () => ({
    event: 'click',
    action: OskellyAnalyticsActionType.CartUnfinishedOrders,
  }),
  [AnalyticsEvents.AboutServiceClick]: (data) => {
    const info = data?.info as ECartInfo;

    let action = OskellyAnalyticsActionType.InfoExpertise;

    if (info === ECartInfo.Delivery) action = OskellyAnalyticsActionType.InfoDelivery;
    if (info === ECartInfo.Security) action = OskellyAnalyticsActionType.InfoSafeBottom;
    if (info === ECartInfo.Help) action = OskellyAnalyticsActionType.InfoHelp;

    return {
      event: 'click',
      action,
      ...(data?.page ? { page: data?.page } : {}),
    };
  },
  [AnalyticsEvents.ShowAllClick]: (data) => ({
    event: 'click',
    action: 'show_all',
    chapter: data?.chapter as string,
    chapter_index: data?.chapter_index,
    segment_id: data?.segment_id,
    id_chapter: data?.id_chapter,
    item: data?.item as string,
    product_id: data?.product_id as string,
    value: data?.value,
  }),
  [AnalyticsEvents.AddToCart]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.AddToCart,
    product_id: data?.product_id as number,
    chapter: data?.chapter as string,
  }),
  [AnalyticsEvents.ProductsBlockView]: (data) => ({
    event: 'view',
    chapter: data?.chapter as string,
    product_id: data?.product_id as number,
    value: data?.value,
  }),
  [AnalyticsEvents.CartLoginView]: (data) => ({
    event: 'view',
    page: data?.page,
  }),

  [AnalyticsEvents.CheckoutView]: (data) => ({
    event: 'view',
    page: data?.page as string,
    id_item: data?.idItem as number[],
  }),
  [AnalyticsEvents.Comment]: () => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Comment,
  }),
  [AnalyticsEvents.Promocode]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Promocode,
    value: data?.value,
  }),
  [AnalyticsEvents.CheckoutPay]: (data) => ({
    event: 'view',
    page: data?.page,
    chapter: 'with',
    id_item: data?.idItem as number[],
  }),
  [AnalyticsEvents.CheckoutCardView]: (data) => ({
    event: 'view',
    page: data?.page,
  }),
  [AnalyticsEvents.CheckoutCardClick]: (data) => ({
    event: 'click',
    action: data?.action as OskellyAnalyticsActionType,
    page: data?.page,
  }),
  [AnalyticsEvents.CheckoutGoPayment]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Checkout,
    page: data?.page as string,
    item: data?.item as string,
  }),
  [AnalyticsEvents.CheckoutGoPaymentOtherCard]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.PayOtherCard,
    page: data?.page,
  }),
  [AnalyticsEvents.CheckoutGoPaymentNoCards]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.PayNoCards,
  }),
  [AnalyticsEvents.CheckoutChangePaymentType]: (data) => ({
    event: 'click',
    page: data?.page as string,
    action: OskellyAnalyticsActionType.Payment,
    value: data?.value,
  }),
  [AnalyticsEvents.CheckoutCompleteView]: (data) => {
    const order = data?.order as OrderDTO;

    return {
      event: 'view',
      value: order?.finalAmount?.toString(),
      item: 'RUB',
      id_item: order?.items?.map(({ id }) => id).join(','),
    };
  },
  [AnalyticsEvents.CheckoutCompleteMain]: () => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Main,
  }),
  [AnalyticsEvents.CheckoutCompleteWishlist]: () => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Wishlist,
  }),
  [AnalyticsEvents.CheckoutThanksProductClick]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Product,
    id_item: String(data?.product_id),
  }),

  [AnalyticsEvents.DialogLoginTab]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Login,
    page: data?.page,
  }),
  [AnalyticsEvents.DialogSignUpTab]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.SignIn,
    page: data?.page,
  }),
  [AnalyticsEvents.DialogLoginRemember]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Remember,
    page: data?.page,
  }),
  [AnalyticsEvents.DialogLoginAccepted]: (data) => ({
    event: 'click',
    action: OskellyAnalyticsActionType.Accepted,
    page: data?.page,
    value: data?.value,
  }),

  [AnalyticsEvents.EnterLogin]: (data) => {
    const result: any = {
      event: 'click',
      action: OskellyAnalyticsActionType.EnterLogin,
      chapter: data?.chapter,
    };

    if (data?.page) {
      result.page = data.page;
    }

    return result;
  },

  [AnalyticsEvents.Registration]: (data) => {
    const result: any = {
      event: 'click',
      action: OskellyAnalyticsActionType.Registration,
    };

    if (data?.page) {
      result.page = data.page;
    }

    return result;
  },

  [AnalyticsEvents.SliderNext]: (data) => ({
    event: 'click',
    item_id: data?.item_id as number,
    chapter: data?.chapter as string,
    item: 'next',
    value: data?.value,
  }),
  [AnalyticsEvents.SliderPrev]: (data) => ({
    event: 'click',
    item_id: data?.item_id as number,
    chapter: data?.chapter as string,
    item: 'prev',
    value: data?.value,
  }),

  [AnalyticsEvents.MenuLinkClick]: (data) => ({
    event: 'click',
    category_id: data?.category_id as string,
    item: data?.item,
  }),
  [AnalyticsEvents.CatalogMobileView]: (data) => ({
    event: 'view',
    page: data?.page,
  }),
  [AnalyticsEvents.CatalogMobileLinkClick]: (data) => ({
    event: 'click',
    id_item: data?.id_item as number,
    item: data?.item,
    page: data?.page,
    chapter: data?.chapter,
  }),
  [AnalyticsEvents.HeaderLinkClick]: (data) => ({
    event: 'click',
    item: data?.item,
  }),
  [AnalyticsEvents.FiltersLinkClick]: (data) => ({
    event: 'click',
    id_item: data?.id_item,
    page: data?.page,
    action: data?.action,
    chapter: data?.chapter,
    id_chapter: data?.id_chapter,
    value: data?.value,
    value_id: data?.value_id,
  }),
  [AnalyticsEvents.FiltersView]: (data) => ({
    event: 'view',
    page: data?.page,
    id_item: data?.id_item,
  }),
  [AnalyticsEvents.PageView]: (data) => ({
    event: 'view',
    id_item: data?.id_item,
  }),
  [AnalyticsEvents.MainBlockView]: (data) => ({
    event: 'view',
    chapter: data?.chapter,
    id_chapter: data?.id_chapter,
    id_item: data?.id_item,
    index: data?.index,
    item: data?.item,
  }),
  [AnalyticsEvents.MainBlockClick]: (data) => ({
    event: 'click',
    chapter: data?.chapter,
    id_chapter: data?.id_chapter,
    id_item: data?.id_item,
    index: data?.index,
    item: data?.item,
  }),

  [AnalyticsEvents.ClickEvent]: (data) => {
    const result: any = {
      event: 'click',
      chapter: data?.chapter,
      id_chapter: data?.id_chapter,
      id_item: data?.id_item,
      item_id: data?.item_id,
      index: data?.index,
      item: data?.item,
      chapter_index: data?.chapter_index,
      segment_id: data?.segment_id,
      action: data?.action,
      product_id: data?.product_id,
      value: data?.value,
    };

    if (data?.page) {
      result.page = data.page;
    }

    return result;
  },
  [AnalyticsEvents.BlockView]: (data) => {
    const result: any = {
      event: 'view',
      chapter: data?.chapter,
      id_chapter: data?.id_chapter,
      id_item: data?.id_item,
      index: data?.index,
      item: data?.item,
      chapter_index: data?.chapter_index,
      segment_id: data?.segment_id,
      action: data?.action,
      value: data?.value,
    };

    if (data?.page) {
      result.page = data.page;
    }

    if (data?.productId) {
      result.product_id = data.productId;
    }

    return result;
  },
  [AnalyticsEvents.SwiperBlockView]: (data) => ({
    event: 'view',
    chapter: data?.chapter,
    id_chapter: data?.id_chapter,
    id_item: data?.id_item,
    index: data?.index,
    item: data?.item,
    chapter_index: data?.chapter_index,
    segment_id: data?.segment_id,
  }),
  [AnalyticsEvents.SwiperBlockClick]: (data) => ({
    event: 'click',
    action: data?.action,
    chapter: data?.chapter,
    id_chapter: data?.id_chapter,
    id_item: data?.id_item,
    index: data?.index,
    item: data?.item,
    chapter_index: data?.chapter_index,
    segment_id: data?.segment_id,
    value: data?.value,
  }),
  [AnalyticsEvents.YandexSplitInfo]: (data) => ({
    event: 'click',
    id_item: data?.productId?.toString() ?? '',
    action: OskellyAnalyticsActionType.YandexSplitInfo,
  }),
  [AnalyticsEvents.YandexSplitInfoBackToItem]: (data) => ({
    event: 'click',
    page: data?.page as string,
    action: OskellyAnalyticsActionType.YandexSplitInfoBackToItem,
  }),
  [AnalyticsEvents.YandexSplitInfoClose]: (data) => ({
    event: 'click',
    page: data?.page as string,
    action: OskellyAnalyticsActionType.YandexSplitInfoClose,
  }),
  [AnalyticsEvents.OCommunityBadgeClick]: (data) => ({
    event: 'click',
    page: data?.page as string,
    item: 'badge',
    id_item: data?.id_item as string,
    id_chapter: data?.id_chapter as string,
  }),
  [AnalyticsEvents.OCommunityBadgeView]: (data) => ({
    event: 'view',
    page: data?.page as string,
    id_chapter: data?.id_chapter as string,
  }),
  [AnalyticsEvents.OCommunityDialogClose]: (data) => ({
    event: 'click',
    action: 'close',
    page: data?.page as string,
  }),
  [AnalyticsEvents.OCommunityDialogView]: (data) => ({
    event: 'view',
    page: data?.page as string,
  }),
  [AnalyticsEvents.ExclusiveSelectionDetailsClick]: (data) => ({
    event: 'click',
    page: data?.page as string,
    product_id: data?.product_id as number,
    action: OskellyAnalyticsActionType.ExclusiveSelectionDetailsClick,
    value: data?.value,
  }),
};

export function compilePageParam(page?: string | null) {
  if (!page) return undefined;

  if (page?.includes('/products/')) {
    return 'product_page';
  }

  const pageNameMapper: Record<RouteRecordName, string> = {
    cart: 'cart',
    checkout: 'checkout',
    'orders-orderId-thanks': 'checkout_success',
    'catalog-catalog': 'catalog',
    women: 'main',
    kids: 'main',
    men: 'main',
    lifestyle: 'main',
    'homeBanner-homeBannerId': 'second_main',
    'brands-category-brand': 'catalog_brand',
    'products-product': 'product_page',
    'profile-profileId-wishlist': 'wishlist',
    'profile-profileId': 'profile_page',
  };

  let pageName = pageNameMapper[page];

  if (pageName) return pageName;

  switch (page) {
    case '':
    case 'kids':
    case 'men':
    case 'women':
    case 'lifestyle':
      pageName = 'main';
      break;
    case 'products':
      pageName = 'product';
      break;
    default:
      break;
  }

  return pageName ? `${pageName}_page` : String(page);
}

function getOskellyEvent(name: AnalyticsEvents, data?: AnyObject) {
  const currEvent = oskellyEventsMap[name];

  return currEvent ? currEvent(data) : null;
}

export function useOskellyAnalytics() {
  const { account } = useAccountStore();
  const { experimentIds, experimentValueIds } = useExperimentsStore();
  const osk = useCookie('osk');
  const { saveAnalyticsEvent } = useAnalyticsProvider();
  const { activeMenuCategoryId } = useActiveMenuCategory();

  function compileMainData() {
    const deviceType = ua?.device.type ?? (ua?.os.name?.includes('Mac') || ua?.os.name?.includes('Win') ? 'desktop' : 'unknown');
    const os = `${ua?.os.name ?? 'unknown'} ${ua?.os.version ?? 'unknown'}`;
    const route = useRoute();

    const page = compilePageParam(route.name as string);

    const eventData: OskellyAnalyticsEventMainData = {
      os,
      language: navigator.language ?? 'unknown',
      device_type: deviceType,
      platform: 'web',
      version: '1',
      guest_token: osk.value ?? 'unknown',
      user_id: account.value?.id ?? 0,
      time: Math.round(Date.now() / 1000),
      page,
    };

    if (activeMenuCategoryId.value) {
      eventData.category_id = activeMenuCategoryId.value;
    }

    if (page === 'second_main' && route.params.homeBannerId) {
      eventData.id_page = route.params.homeBannerId as string;
    }

    if (experimentIds.value?.length && experimentValueIds.value?.length) {
      eventData.experiment_ids = experimentIds.value;
      eventData.experiment_value_ids = experimentValueIds.value;
    }

    return eventData;
  }

  function addEvent(name: AnalyticsEvents, data?: AnyObject) {
    return new Promise<void>((resolve) => {
      const oskellyEvent = getOskellyEvent(name, data);

      if (!oskellyEvent) {
        resolve();
        return;
      }

      try {
        saveAnalyticsEvent({
          ...compileMainData(),
          ...oskellyEvent,
        });
      } catch (error) {
        console.error(error);
      } finally {
        resolve();
      }
    });
  }

  return {
    addEvent,
  };
}
